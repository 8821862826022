import axios from "axios";
import { UserStore } from "stores";
import type {
  FromsRespons,
  FromsAnswersReq,
  FileUploadRespons,
} from "./apiTypes";
import type { FormsTypes, RequestedForm } from "helpers/types";

export const getRequestedForms = async () =>
  axios
    .get<RequestedForm[]>("/form/requested")
    .then(({ data }) => {
      UserStore.setRequestedForms(data);
    })
    .catch((err) => console.log("form request err", err));

export const getFormByType = async (type: FormsTypes) =>
  axios.get<FromsRespons>(`/form/type/${type}`);

export const sendForm = async (formId: number, answers: FromsAnswersReq) =>
  axios.post(`/form/${formId}`, answers);

export const uploadFile = async (file: any) =>
  axios.post<FileUploadRespons>(
    `/file/upload`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
