import { FC } from "react";
import styled from "styled-components";
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AppText } from "components";
import { colors } from "helpers/consts";
import type { DateInputProps, DateControlProps } from "./types";
import type { Moment } from "moment";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-plus.svg";

const DateInput: FC<DateInputProps> = ({
  value,
  setValue,
  label,
  withTime,
  maxWidth,
  name,
  withFutureDates,
  helperText,
  error,
  isUTC,
}) => {
  const handleChange = (date: Moment | string | null) => {
    if (typeof date === "string") {
      return;
    }
    setValue(date);
  };

  return (
    <StyledFormControl fullWidth $isError={error} $maxWidth={maxWidth}>
      {withTime ? (
        <>
          <DateTimePicker
            slotProps={{ textField: { name: name } }}
            value={value}
            label={label}
            onChange={handleChange}
            disableFuture={!withFutureDates}
            ampm={false}
            slots={{ openPickerIcon: CalendarIcon }}
            timezone={isUTC ? "UTC" : "default"}
          />
          {helperText && (
            <AppText
              fontSize={11}
              color={colors.error_500}
              style={{ margin: "3px 14px 0px" }}
            >
              {helperText}
            </AppText>
          )}
        </>
      ) : (
        <>
          <DatePicker
            slotProps={{ textField: { name: name } }}
            value={value}
            label={label}
            onChange={handleChange}
            disableFuture={!withFutureDates}
            slots={{ openPickerIcon: CalendarIcon }}
            timezone={isUTC ? "UTC" : "default"}
          />
          {helperText && (
            <AppText
              fontSize={11}
              color={colors.error_500}
              style={{ margin: "3px 14px 0px" }}
            >
              {helperText}
            </AppText>
          )}
        </>
      )}
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)<DateControlProps>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  min-width: 20rem;

  fieldset > legend > span {
    font-size: 1.2rem;
  }

  .MuiInputLabel-root {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${colors.gray_500};
  }

  .MuiInputLabel-root:has(+ .MuiInputBase-root:not(.Mui-focused)) {
    transform: translate(1.4rem, 1rem) scale(1);
  }

  .MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75);
  }

  .MuiTextField-root > .MuiInputBase-root {
    font-weight: 500;
    font-size: 1.6rem;
    color: ${colors.gray_900};
    border-radius: 0.8rem;
    padding-right: 1.4rem;
    background-color: ${colors.white};
    ${({ disabled }) => disabled && `background-color: ${colors.gray_50};`}

    &:not(::disabled):hover,
    &:not(::disabled):focus {
      fieldset {
        border: 1px solid ${colors.primary_500};
      }
    }
    &:not(::disabled):focus-within {
      fieldset {
        border: 2px solid ${colors.primary_500};
      }
    }
  }

  .MuiInputBase-input {
    padding: 1rem 1.4rem;

    label {
      font-size: 1.6rem;
    }
  }
  .MuiIconButton-root {
    margin-right: 0;
    > svg {
      height: 2rem;
      width: 2rem;
    }
  }
  .MuiSelect-select {
    fieldset {
      border: 1px solid ${colors.gray_300};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.primary_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  fieldset {
    border: ${({ $isError }) =>
      $isError
        ? `2px solid ${colors.error_500}`
        : `1px solid
      ${colors.gray_300}`};
    border-radius: 0.8rem;
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export default DateInput;
