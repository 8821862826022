import { FC, ReactNode } from "react";
import styled from "styled-components";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { InfoBlock } from "./ReadInfo";
import { TextInputProps } from "./types";
import { colors } from "helpers/consts";

interface StyledTextInputProps extends TextInputProps {
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
}

const StyledTextInput: FC<StyledTextInputProps> = ({
  value,
  disabled,
  leftAdornment,
  rightAdornment,
  setValue,
  ...props
}) => {
  return (
    <StyledTextField
      value={value}
      disabled={disabled}
      onChange={
        setValue ? ({ target: { value } }) => setValue(value) : () => null
      }
      endAdornment={
        rightAdornment && (
          <InputAdornment position="end">{rightAdornment}</InputAdornment>
        )
      }
      startAdornment={leftAdornment && <InfoBlock>{leftAdornment}</InfoBlock>}
      fullWidth
      {...props}
    />
  );
};

const StyledTextField = styled(OutlinedInput)`
  padding-left: 0px;
  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiInputBase-input {
    padding: 1.6rem 0.6rem 1.6rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${colors.gray_700};
    border-radius: 0.8rem;
    background-color: ${colors.white};
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus {
      color: ${colors.primary_600};
    }
    &:disabled {
      color: ${colors.gray_500};
      background-color: ${colors.gray_50};
    }
    &:disabled + fieldset {
      border: 1px solid ${colors.gray_100};
    }
  }
  .MuiInputBase-root {
    border-radius: 0.8rem;
    ${({ disabled }) => disabled && `background-color: ${colors.gray_50};`}
  }
  fieldset {
    border: 1px solid ${colors.gray_100};
    border-radius: 0.8rem;
  }
  .MuiInputAdornment-root {
    height: 100%;
    margin-left: 0;
    max-height: 5.6rem;
    min-height: 5.6rem;
  }
`;

export default StyledTextInput;
