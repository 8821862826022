import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
} from "components/Buttons";
import { AppText } from "components";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import CopyTradingRows from "pages/EarningPage/components/CopyTradingRows";
import { colors, currencyIcons } from "helpers/consts";
import type { CurrencyInterface } from "helpers/types";
import {
  ModalContent,
  InnerContentForm,
  ModalHeading,
  ConfirmBlock,
  ConfirmInfo,
} from "../styled";

interface ConfirmCopyTradingStepProps {
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  currencyValue: string;
  currencies: CurrencyInterface[] | null;
}

const ConfirmCopyTradingStep: FC<ConfirmCopyTradingStepProps> = ({
  onClose,
  currencyValue,
  currencies,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <ModalHeading $centered>Copy Trading confirmation</ModalHeading>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onClose} />

        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <ConfirmBlock>
            {currencyIcons["USDC"]}
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {normolizeDecimalsWithNoCode(
                Number(currencyValue),
                "USDC",
                currencies
              )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {" "}
                {"USDC"}
              </AppText>
            </AppText>
            <ConfirmInfo>
              <AppText fontWeight={400} color={colors.gray_700}>
                {t("STAKE_CONFIRM")} USDC?
              </AppText>
            </ConfirmInfo>
          </ConfirmBlock>

          <CopyTradingRows
            currencyValue={currencyValue}
            currencies={currencies}
            currency="USDC"
          />

          <ModalButton type="submit">Start copy trading</ModalButton>
        </InnerContentForm>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmCopyTradingStep;
