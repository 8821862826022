import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Snackbar, SnackbarProps, Slide, Alert } from "@mui/material";
import { GlobalStore } from "stores";

const SnackBar: FC<SnackbarProps> = () => {
  const { setError, error } = GlobalStore;
  const [isOpen, setisOpen] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setisOpen(true);
    }
  }, [error]);

  const handleClose = () => {
    setisOpen(false);
  };
  return (
    <Snackbar
      open={isOpen}
      key={error ? error : undefined}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={Slide}
      TransitionProps={{ onExited: () => setError("") }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {error}
      </Alert>
    </Snackbar>
  );
};

export default observer(SnackBar);
