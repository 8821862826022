export const emailValidation =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const pnoneInputValidator = new RegExp(/^[0-9]+$/);

export const latinInputValidator = new RegExp(/^[a-zA-Z]*$/);

export const nameInputValidator = new RegExp(/^[A-Za-z '-]+$/);

export const streetInputValidator = new RegExp(/^[A-Za-z0-9 '-/,]+$/);

export const manualNumInputValidator = (decimals: number) =>
  new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,${decimals}}$)`);
