import { FC, ReactNode } from "react";
import styled from "styled-components";
import SimpleBarReact from "simplebar-react";
import { Fade, Modal, Backdrop, ModalProps } from "@mui/material";
import { colors } from "helpers/consts";

interface BasicModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExited?: () => void;
  title?: string;
  description?: string;
  isInsideModal?: boolean;
  isLarge?: boolean;
  children: ReactNode;
}

const BasicModal: FC<BasicModalProps> = ({
  isOpen,
  onClose,
  onExited,
  title,
  description,
  isInsideModal,
  isLarge,
  children,
}) => {
  return (
    <StyledModal
      aria-labelledby={title ? title : ""}
      aria-describedby={description ? description : ""}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      $isInsideModal={isInsideModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      onTransitionExited={onExited}
    >
      <Fade in={isOpen}>
        <ModalContainer $isLarge={isLarge}>
          <SimpleBarReact
            style={{
              width: "100%",
              maxHeight: "calc(92vh - 2px)",
            }}
            tabIndex={-1}
          >
            {children}
          </SimpleBarReact>
        </ModalContainer>
      </Fade>
    </StyledModal>
  );
};

interface StyledModalProps extends ModalProps {
  $isInsideModal?: boolean;
}

const StyledModal = styled(Modal)<StyledModalProps>`
  ${({ $isInsideModal }) =>
    $isInsideModal &&
    ".MuiBackdrop-root:not(.MuiBackdrop-invisible) {backdrop-filter: blur(0px);background-color: transparent;}"}
`;
interface ModalContainerProps {
  $isLarge?: boolean;
}
const ModalContainer = styled.div<ModalContainerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 92vh;
  max-width: ${({ $isLarge }) => ($isLarge ? "100rem" : "60rem")};
  // min-height: 43rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_200};
  border-radius: 1.2rem;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .MuiTooltip-tooltip {
    font-size: 1rem;
  }
`;

export default BasicModal;
