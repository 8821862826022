import { FC } from "react";
import styled from "styled-components";
import { AppText } from "components";
import { colors } from "helpers/consts";

interface InfoBlockProps {
  text: string;
  isSuccess?: boolean;
  noMargin?: boolean;
}

const InfoBlock: FC<InfoBlockProps> = ({ text, isSuccess, noMargin }) => (
  <BlockContainer $isSuccess={isSuccess} $noMargin={noMargin}>
    <AppText color={isSuccess ? colors.success_500 : colors.error_500}>
      {text}
    </AppText>
  </BlockContainer>
);

interface BlockContainerProps {
  $isSuccess?: boolean;
  $noMargin?: boolean;
}
const BlockContainer = styled.div<BlockContainerProps>`
  width: 100%;
  border: solid 2px
    ${({ $isSuccess }) => ($isSuccess ? colors.success_500 : colors.error_500)};
  border-radius: 1.6rem;
  padding: 0.6rem 1.2rem;
  background-color: ${({ $isSuccess }) =>
    $isSuccess ? colors.success_50 : colors.error_50};
  margin: ${({ $noMargin }) => ($noMargin ? "0" : "1rem 0 2rem")};
`;

export default InfoBlock;
