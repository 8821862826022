import { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MainButton } from "components/Buttons";
import type { StakeType, AvailablePayments } from "helpers/types";

import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";

interface StakingControlsProps {
  openStakeModal?: (
    initialCoin: AvailablePayments,
    initialStakeStrategyId?: number,
    stakeType?: StakeType
  ) => void;
  initialCoin: AvailablePayments;
  initialStakeStrategyId?: number;
  linkTo?: string;
  isUnstakeDisabled?: boolean;
}

const StakingControls: FC<StakingControlsProps> = ({
  openStakeModal,
  initialCoin,
  initialStakeStrategyId,
  linkTo,
  isUnstakeDisabled = false,
}) => {
  const navigate = useNavigate();
  return (
    <TextButtons>
      <MainButton
        variant="text"
        toFillSvg
        onClick={() =>
          linkTo
            ? navigate(linkTo)
            : openStakeModal
            ? openStakeModal(initialCoin, initialStakeStrategyId)
            : null
        }
      >
        <ArrowDownIcon />
        Stake
      </MainButton>
      <MainButton
        variant="text"
        onClick={() =>
          linkTo
            ? navigate(linkTo)
            : openStakeModal
            ? openStakeModal(initialCoin, initialStakeStrategyId, "unstake")
            : null
        }
        disabled={isUnstakeDisabled}
      >
        <ArrowUpIcon />
        Unstake
      </MainButton>
    </TextButtons>
  );
};

const TextButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  & > button {
    padding: 1rem 0.6rem;

    & > *:not(:first-child) {
      margin-left: 0.4rem;
    }

    > svg {
      min-width: 1.6rem;
      min-height: 1.6rem;
      max-height: 1.6rem;
      max-width: 1.6rem;
    }
  }
`;

export default StakingControls;
