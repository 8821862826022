import { FC, ReactElement } from "react";
import { Tooltip, TooltipProps } from "@mui/material";

interface LayoutProps extends TooltipProps {
  children: ReactElement<any, any>;
  title: string;
}

const AppTooltip: FC<LayoutProps> = ({ children, title, ...props }) => (
  <Tooltip
    title={title}
    arrow
    placement="top"
    PopperProps={{
      disablePortal: true,
    }}
    {...props}
  >
    {children}
  </Tooltip>
);

export default AppTooltip;
