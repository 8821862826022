import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { colors } from "helpers/consts";
import styled from "styled-components";

const OuterLink = styled.a`
  font-weight: 600;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: ${colors.primary_600};

  &:hover,
  &:focus {
    color: ${colors.primary_500};
    text-decoration: underline;
  }
  &:active {
    color: ${colors.primary_700};
  }
`;

const InnerLink = styled(Link)`
  font-weight: 600;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: ${colors.primary_600};

  &:hover,
  &:focus {
    color: ${colors.primary_500};
    text-decoration: underline;
  }
  &:active {
    color: ${colors.primary_700};
  }
`;

interface LinkProps {
  children: ReactNode;
  isOuterLink?: boolean;
  isEmail?: boolean;
  to: string;
}
const StyledLink: FC<LinkProps> = ({
  isOuterLink = false,
  isEmail,
  children,
  to,
}) => (
  <>
    {!isOuterLink ? (
      <InnerLink to={to}>{children}</InnerLink>
    ) : (
      <OuterLink
        href={isEmail ? `mailto:${to}` : to}
        target={isEmail ? "_self" : "_blank"}
        rel={!isEmail ? "noreferrer" : undefined}
      >
        {children}
      </OuterLink>
    )}
  </>
);

export default StyledLink;
