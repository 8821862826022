import { FC } from "react";
import styled from "styled-components";
import { AppText, Tooltip } from "components";
import { colors } from "helpers/consts";

const stackBarColors = [
  "linear-gradient(360deg, #26A17B 0%, rgba(38, 161, 123, 0.60) 100%)",
  "linear-gradient(360deg, #2775CA 0%, rgba(39, 117, 202, 0.60) 100%)",
  "linear-gradient(360deg, #F7931A 0%, rgba(247, 147, 26, 0.60) 100%)",
  "linear-gradient(360deg, #FF060A 0%, rgba(255, 6, 10, 0.60) 100%)",
  "linear-gradient(360deg, #627EEA 0%, rgba(98, 125, 234, 0.60) 100%)",
];
// const otherStackBarColor =
//   "linear-gradient(180deg, #E7EBEF 0%, #C8D1DA 100%)";
export interface StackBarObj {
  text: string;
  percent: number;
}
interface StackBarProps {
  data: StackBarObj[];
  withText?: boolean;
}

const StackBar: FC<StackBarProps> = ({ data, withText }) => {
  return (
    <BarChart>
      {data &&
        data
          .sort((a, b) => b.percent - a.percent)
          .map(({ percent, text }, idx) => {
            return (
              <Tooltip key={text} title={`${text} ${percent}%`}>
                <BarData
                  style={{
                    background: `${
                      stackBarColors[idx % stackBarColors.length]
                    }`,
                    width: `${percent}%`,
                  }}
                >
                  <AppText
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    style={{ pointerEvents: "none" }}
                  >{`${withText && percent > 15 ? (text ? text : "") : ""}${
                    percent > 25 ? `${withText ? " " : ""}${percent}%` : ""
                  }`}</AppText>
                </BarData>
              </Tooltip>
            );
          })}
    </BarChart>
  );
};

const BarChart = styled.div`
  background-color: ${colors.white};
  border-radius: 1.8rem;
  display: flex;
  height: 2.8rem;
  width: 100%;
  max-width: 88rem;
  overflow: hidden;
`;

const BarData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StackBar;
