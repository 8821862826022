import { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { ModalHeading, ModalContent, ModalSemiHeading } from "./styled";
import { ExternalHtml } from "pages/CryptoCardsPage/styled";
import {
  copyTradingBenefits,
  copyTradingWorks,
  copyTradingCommissions,
} from "pages/EarningPage/dummyData";

interface TermsAndConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading>{t("TERMS_AND_CONDITIONS")}</ModalHeading>

        <ModalSemiHeading>How it works</ModalSemiHeading>
        <ExternalHtml
          style={{ padding: "0rem" }}
          dangerouslySetInnerHTML={{ __html: copyTradingWorks }}
        />

        <ModalSemiHeading>Benefits</ModalSemiHeading>
        <ExternalHtml
          style={{ padding: "0rem" }}
          dangerouslySetInnerHTML={{ __html: copyTradingBenefits }}
        />

        <ModalSemiHeading>Commissions</ModalSemiHeading>
        <ExternalHtml
          style={{ padding: "0rem" }}
          dangerouslySetInnerHTML={{ __html: copyTradingCommissions }}
        />
      </ModalContent>
    </BasicModal>
  );
};

export default observer(TermsAndConditionsModal);
