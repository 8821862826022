import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Inputs";
import { CoinCell } from "components/Table";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import {
  TopSemiContent,
  ModalHeading,
  CurrencyOptions,
  CurrencyOption,
  CurrencyLine,
  SemiContent,
} from "./styled";
import type {
  ModalTypes,
  AvailableTradeOptions,
  CurrencyInterface,
} from "helpers/types";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

export interface AddNewWalletModalProps {
  isOpen: boolean;
  availableNewCurrencies: CurrencyInterface[];
  onClose: () => void;
  openOtherModal: (
    type: ModalTypes,
    initialCoin: AvailableTradeOptions
  ) => void;
}

const AddNewWalletModal: FC<AddNewWalletModalProps> = ({
  isOpen,
  onClose,
  openOtherModal,
  availableNewCurrencies,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");

  const onSelect = async (option: CurrencyInterface) => {
    openOtherModal("deposit", option.name);
    onClose();
  };

  const searchedOptions = useMemo(
    () =>
      availableNewCurrencies &&
      availableNewCurrencies.filter(({ name }) =>
        name?.toLowerCase().includes(search.toLowerCase())
      ),
    [availableNewCurrencies, search]
  );

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={() => setSearch("")}
      onClose={onClose}
    >
      <SemiContent>
        <CloseModalButton onClose={onClose} label={t("CLOSE_SELECT")} />
        <TopSemiContent>
          <ModalHeading>{t("SELECT_ASSET")}</ModalHeading>
          <TextInput
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            leftIcon={<SearchIcon />}
            placeholder={t("SEARCH")}
          />
        </TopSemiContent>
        <CurrencyOptions>
          {searchedOptions?.map((coin) => (
            <CurrencyOption key={coin.name} onClick={() => onSelect(coin)}>
              <CurrencyLine>
                <CoinCell text={coin.name} coin={coin.name} />
              </CurrencyLine>
            </CurrencyOption>
          ))}
        </CurrencyOptions>
      </SemiContent>
    </BasicModal>
  );
};

export default AddNewWalletModal;
