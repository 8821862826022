import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { InfoRows } from "components/Modals/styled";
import type { CurrencyInterface } from "helpers/types";

interface StakeMillionRowsProps {
  currencyValue: string;
  currencies: CurrencyInterface[] | null;
}

const StakeMillionRows: FC<StakeMillionRowsProps> = ({
  currencyValue,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("YOU_SPEND")}
        value={`${normolizeCurrenciesDecimals(
          Number(currencyValue),
          "USDT",
          currencies,
          "forView"
        )} USDT`}
      />

      <ModalRow keyText="Staking Period" value="12 month" />
      <ModalRow keyText="Reward option" value="Monthly" />

      <ModalRow keyText="Reward %" value="4.8% APM" isChip isInfoChip />

      <ModalRow
        keyText="Estimated reward"
        value={`${normolizeCurrenciesDecimals(
          Number(currencyValue) * 0.048,
          "USDT",
          currencies,
          "forView"
        )} USDT`}
      />
    </InfoRows>
  );
};

export default StakeMillionRows;
