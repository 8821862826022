import { FC } from "react";
import { Outlet, Navigate } from "react-router-dom";
import routesPaths from "routes/routesPaths";

interface PrivateRouteProps {
  isAuth: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ isAuth }) => {
  if (!isAuth && !localStorage.getItem("access")) {
    return <Navigate to={routesPaths.login} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
