import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { InfoRows } from "../styled";
import type { StakeStrategyInterface, CurrencyInterface } from "helpers/types";

interface StakeInfoRowsProps {
  stakeValue: string;
  strategy: StakeStrategyInterface | null;
  currencies: CurrencyInterface[] | null;
  cost: number;
}

const StakeInfoRows: FC<StakeInfoRowsProps> = ({
  stakeValue,
  strategy,
  currencies,
  cost,
}) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("YEARLY_REWARD")}
        value={`APY ${
          strategy
            ? strategy.payoutMax
              ? strategy.payoutMax
              : strategy.payoutMin || 0
            : 0
        }%`}
        isChip
      />

      <ModalRow
        keyText={t("ACCURAL_PERIOD")}
        value={`Every ${
          Math.floor((strategy?.payoutFrequency || 0) / 3600) < 24
            ? `${Math.floor((strategy?.payoutFrequency || 0) / 3600)} hour`
            : `${Math.floor((strategy?.payoutFrequency || 0) / 3600 / 24)} days`
        }`}
      />

      <Accordion
        label={t("TOTAL")}
        subLabel={toLocaleStringWithCurrency(
          Number(stakeValue) > 0 ? Number(stakeValue) : 0,
          strategy?.currencyName || "USDT",
          currencies
        )}
      >
        <ModalRow
          keyText={t("UNSTACKING_FEE")}
          value={`${
            Number(strategy?.unstakingFixedFee) > 0
              ? `${strategy?.unstakingFixedFee} ${
                  strategy?.currencyName || "USDT"
                }`
              : `${strategy?.unstakingPercentFee || 0}%`
          }`}
          isBold
        />

        <ModalRow
          keyText={`${t("VALUE")} USD`}
          value={toLocaleStringWithCurrency(
            Number(stakeValue) > 0 ? Number(stakeValue) * cost : 0,
            "USD"
          )}
        />
      </Accordion>
    </InfoRows>
  );
};

export default StakeInfoRows;
