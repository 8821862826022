import styled from "styled-components";
import { colors } from "helpers/consts";
interface ContainerInterface {
  $isSingle?: boolean;
}
export const Container = styled.div<ContainerInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 1.6rem;
  }

  @media (min-width: 66em) {
    & > *:not(:first-child) {
      margin-top: 3.2rem;
    }
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  position: relative;
  border: 1px solid ${colors.gray_200};
  background-color: ${colors.white};
  margin-bottom: 2.4rem;

  @media (min-width: 36em) {
    border-radius: 1.2rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 1.6rem;

  & > *:not(:first-child) {
    margin-top: 1.4rem;
  }

  @media (min-width: 36em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.4rem;
  }
`;

export const WidgetContainer = styled.div`
  width: 100%;
`;
