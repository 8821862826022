import { FC, useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  CloseModalButton,
  BackModalButton,
  MainButton,
} from "components/Buttons";
import BasicModal from "./BasicModal";
import { TwoFaModal } from "components/Modals";
import { TextInput } from "components/Inputs";
import {
  ModalHeading,
  ModalContent,
  InfoDecorative,
  InputContainer,
} from "./styled";
import { AppText, Preloader } from "components";
import { add2FAVerification } from "api/user";
import { getCreditcoCardPinCode } from "api/creditco";
import { GlobalStore, UserStore } from "stores";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface CardPinModalProps {
  cardId?: number | null;
  isOpen: boolean;
  onClose: () => void;
}
const CardPinModal: FC<CardPinModalProps> = ({ cardId, onClose, isOpen }) => {
  const { t } = useTranslation();

  const {
    user: { is2FAEnabled },
  } = UserStore;

  const [isTwoFaOpen, setIsTwoFaOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twoFaUrl, setTwoFaUrl] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [cardPin, setCardPin] = useState<string>("");

  const change2Fa = useCallback(() => {
    if (is2FAEnabled) {
      setIsTwoFaOpen(true);
      return;
    }
    setIsLoading(true);
    add2FAVerification()
      .then(({ data }) => {
        setTwoFaUrl(data.url);
        setIsTwoFaOpen(true);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [is2FAEnabled, setIsLoading]);

  const getPinCode = useCallback(async () => {
    if (!cardId || cardPin) {
      return;
    }
    setIsLoading(true);

    getCreditcoCardPinCode(cardId, twoFACode)
      .then(({ data }) => {
        setTwoFACode("");
        setCardPin(data.pin);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  }, [twoFACode, cardId, cardPin]);

  useEffect(() => {
    if (twoFACode.length === 6) {
      getPinCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFACode]);

  const resetState = useCallback(() => {
    setIsTwoFaOpen(false);
    setTwoFaUrl("");
    setTwoFACode("");
    setCardPin("");
  }, []);

  return (
    <BasicModal isOpen={isOpen} onExited={resetState} onClose={onClose}>
      <ModalContent>
        <BackModalButton onBack={onClose} label="back" />
        <CloseModalButton onClose={onClose} label="close" />
        {isLoading && <Preloader isStatic />}
        <ModalHeading $centered>{t("CARD_PIN_CODE")}</ModalHeading>

        <InfoDecorative>
          {!is2FAEnabled ? (
            <>
              <AppText fontSize={18} fontWeight={600}>
                {t("TWO_FA_FOR_PIN")}
              </AppText>

              <MainButton
                maxWidth="14rem"
                style={{ marginTop: "1.6rem" }}
                variant="contained"
                onClick={() => change2Fa()}
              >
                <LockIcon />
                {t("ENABLE")}
              </MainButton>
            </>
          ) : (
            <>
              {!cardPin ? (
                <InputContainer $marginTop>
                  <AppText>{t("TWO_FA_FOR_PIN_CODE")}</AppText>
                  <TextInput
                    placeholder={t("CODE")}
                    value={twoFACode}
                    onChange={({ target: { value } }) => setTwoFACode(value)}
                    leftIcon={<LockIcon />}
                    autoComplete="off"
                    name="2fa-code"
                  />
                </InputContainer>
              ) : (
                <>
                  <AppText fontSize={30} fontWeight={600}>
                    {cardPin || "****"}
                  </AppText>
                  <AppText fontSize={12} fontWeight={400}>
                    {t("KEEP_PIN_CODE_SAFE")}
                  </AppText>
                </>
              )}
            </>
          )}
        </InfoDecorative>
      </ModalContent>
      <TwoFaModal
        isOpen={isTwoFaOpen}
        url={twoFaUrl}
        onClose={() => setIsTwoFaOpen(false)}
      />
    </BasicModal>
  );
};

export default observer(CardPinModal);
