import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { ModalRow } from "components/Rows";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
} from "components/Buttons";
import { Accordion, CardImage, BuyInfoRows } from "components";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { virtualCardDesigns, extraVirtualCardDesigns } from "helpers/consts";
import {
  ModalContent,
  StatusDecorative,
  InfoColumn,
  ModalHeading,
} from "../styled";
import { InfoRows } from "components/Modals/styled";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  FeesInterface,
  CredictcoCardDetails,
  PricesInterface,
} from "helpers/types";

interface ConfirmPurchaseStepProps {
  onClose: () => void;
  onSubmit: () => void;
  product?: CredictcoCardDetails | null;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees?: FeesInterface | null;
  currentFee?: string;
  cost: number;
  isFeesInUsdt?: boolean;
  prices?: PricesInterface | null;
  currencies: CurrencyInterface[] | null;
  eurValue: string;
  isWithdraw?: boolean;
  isTopUp?: boolean;
}

const ConfirmPurchaseStep: FC<ConfirmPurchaseStepProps> = ({
  onClose,
  currencyValue,
  currency,
  fees,
  currentFee,
  cost,
  isFeesInUsdt,
  prices,
  currencies,
  onSubmit,
  product,
  eurValue,
  isWithdraw,
  isTopUp,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <ModalHeading $centered>{t("TRANSACTION_REVIEW")}</ModalHeading>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onClose} />

        <StatusDecorative $noIcon>
          <CardImage
            src={
              product
                ? product.designCode === "CA02"
                  ? extraVirtualCardDesigns[product.cryptoCurrencyName]
                      ?.image || ""
                  : virtualCardDesigns[product.designCode]?.image || ""
                : ""
            }
            alt={
              product
                ? product.designCode === "CA02"
                  ? extraVirtualCardDesigns[product.cryptoCurrencyName]?.name ||
                    ""
                  : virtualCardDesigns[product.designCode]?.name || ""
                : ""
            }
          />
        </StatusDecorative>

        <InfoColumn>
          <InfoRows>
            <ModalRow
              keyText={t("YOU_RECEIVE")}
              value={`≈ ${isWithdraw ? currencyValue : eurValue} ${
                isWithdraw ? currency || "USDT" : product?.currencyName || "EUR"
              }`}
            />
          </InfoRows>
          <Accordion
            label={t("YOU_SPEND")}
            subLabel={`${normolizeCurrenciesDecimals(
              isWithdraw ? eurValue : currencyValue,
              isWithdraw ? product?.currencyName || "EUR" : currency || "USDT",
              currencies,
              "forView"
            )} ${
              isWithdraw ? product?.currencyName || "EUR" : currency || "USDT"
            }`}
          >
            {product && (
              <BuyInfoRows
                cost={cost}
                isFeesInUsdt={isFeesInUsdt}
                prices={prices}
                currencies={currencies}
                currency={currency}
                productCurrency={product.currencyName}
                currencyValue={currencyValue}
                fees={fees}
                currentFee={currentFee}
                userCard
                isWithdraw={isWithdraw}
                isTopUp={isTopUp}
              />
            )}
          </Accordion>

          <ModalButton onClick={onSubmit}>{t("CONFIRM")}</ModalButton>
        </InfoColumn>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmPurchaseStep;
