import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppText } from "components";
import { colors } from "helpers/consts";
import { RowInfo } from "pages/LoginPage/styled";

import { ReactComponent as CheckIcon } from "assets/icons/check-filled.svg";

const requiredLength = 8;

interface PasswordHintRowProps {
  newPassword: string;
  isSuccess?: boolean;
  noMargin?: boolean;
}

const PasswordHintRow: FC<PasswordHintRowProps> = ({
  newPassword,
  isSuccess,
  noMargin,
}) => {
  const [hasValidLength, setHasValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    setHasValidLength(newPassword.length >= requiredLength ? true : false);
    setHasUpperCase(newPassword.toLowerCase() !== newPassword);
    setHasLowerCase(newPassword.toUpperCase() !== newPassword);
    setHasNumber(/\d/.test(newPassword));
    setHasSpecialChar(
      /[ `!@#$%^&*()_/+\-=\]{};':"\\|,.<>?~]/.test(newPassword)
    );
  }, [newPassword]);

  return (
    <RowInfo $isSuccess={isSuccess} $noMargin={noMargin}>
      <CheckIcon />
      <AppText fontWeight={400} color={colors.gray_600}>
        {(!hasValidLength ||
          !hasNumber ||
          !hasUpperCase ||
          !hasLowerCase ||
          !hasSpecialChar) &&
          "Use min."}{" "}
        {!hasValidLength && "8 chars,"} {!hasNumber && "1 number,"}{" "}
        {!hasUpperCase && "1 uppercase,"} {!hasLowerCase && "1 lowercase,"}{" "}
        {!hasSpecialChar && "1 symbol"}
      </AppText>
    </RowInfo>
  );
};

export default observer(PasswordHintRow);
