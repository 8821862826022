import { FC } from "react";
import styled from "styled-components";
import { AppText } from "components";
import { useTranslation } from "react-i18next";

import { ReactComponent as StakeIcon } from "assets/icons/credit-card-upload.svg";
import { ReactComponent as GiftIcon } from "assets/icons/gift.svg";

interface StakingTypeCellProps {
  type: "stake" | "reward";
}

const StakingTypeCell: FC<StakingTypeCellProps> = ({ type }) => {
  const { t } = useTranslation();
  return (
    <StakingTypeContainer>
      {type === "stake" ? <StakeIcon /> : <GiftIcon />}
      <AppText>{type === "stake" ? t("STAKING") : t("REWARD")}</AppText>
    </StakingTypeContainer>
  );
};

const StakingTypeContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }

  & > svg {
    min-width: 2rem;
    max-width: 2rem;
  }
`;

export default StakingTypeCell;
