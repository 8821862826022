import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AppText, Tooltip } from "components";
import { ProgressBar } from "components/Charts";
import { colors } from "helpers/consts";
import { useMediaQuery } from "helpers/hooks";

import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg";

interface InfoRowProps {
  keyText: string;
  value?: string;
  subValue?: string;
  tooltip?: string;
  withDecoration?: boolean;
  barValue?: number;
  minValueWidth?: string;
  isBold?: boolean;
  isSingle?: boolean;
}

const InfoRow: FC<InfoRowProps> = ({
  keyText,
  value,
  subValue,
  tooltip,
  withDecoration,
  barValue,
  minValueWidth,
  isBold,
  isSingle,
}) => {
  const { t } = useTranslation();
  const desktop = useMediaQuery("(min-width: 26em)");
  return (
    <RowContainer
      $withBarValue={typeof barValue === "number"}
      $withSubValue={subValue}
      $withDecoration={withDecoration}
      $isSingle={isSingle}
    >
      <RowInfoLine>
        <AppText fontWeight={isBold ? 600 : 500}>{t(keyText)}</AppText>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon />
          </Tooltip>
        )}
        {!desktop && typeof barValue === "number" && (
          <ProgressBar value={barValue} width="6rem" noMargin />
        )}
      </RowInfoLine>

      <RowInfoColumn>
        <RowValue>
          {desktop && typeof barValue === "number" && (
            <ProgressBar value={barValue} width="6rem" noMargin />
          )}
          {minValueWidth ? (
            <AppText
              style={{ minWidth: desktop ? minValueWidth : "max-content" }}
              fontWeight={isBold ? 600 : 500}
            >
              {value || "-"}
            </AppText>
          ) : (
            <AppText fontWeight={isBold ? 600 : 500}>{value || "-"}</AppText>
          )}
        </RowValue>

        {subValue && (
          <AppText color={colors.gray_400} fontWeight={400}>
            {subValue}
          </AppText>
        )}
      </RowInfoColumn>
    </RowContainer>
  );
};

const RowInfoLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  align-items: center;

  & > *:last-child {
    margin-left: 0.4rem;
  }

  > p {
    text-align: left;
  }
`;
interface RowContainerProps {
  $withSubValue?: string;
  $withDecoration?: boolean;
  $withBarValue?: boolean;
  $isSingle?: boolean;
}
const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  align-items: ${({ $withBarValue }) =>
    $withBarValue ? "flex-start" : "center"};
  flex-direction: ${({ $withBarValue }) => ($withBarValue ? "column" : "row")};
  ${({ $withBarValue }) => !$withBarValue && "justify-content: space-between;"};
  ${({ $isSingle }) =>
    !$isSingle && `border-top: 1px solid ${colors.gray_200};`};

  padding: ${({ $withSubValue }) => ($withSubValue ? "0.6rem" : "1rem")}
    ${({ $isSingle }) => ($isSingle ? "0" : "2.4rem")};
  ${({ $withDecoration }) => {
    if ($withDecoration) {
      return `&:nth-of-type(3n + 1) {
          background: linear-gradient(
          90deg,
          rgba(249, 172, 70, 0) 70%,
          rgba(249, 172, 70, 0.1) 100%
  );};
    &:nth-of-type(3n) {
      background: linear-gradient(
        90deg,
        rgba(6, 118, 71, 0) 70%,
        rgba(6, 118, 71, 0.1) 100%
  );};
    &:nth-of-type(3n - 1) {
      background: linear-gradient(
        90deg,
        rgba(39, 118, 202, 0) 70%,
        rgba(39, 118, 202, 0.1) 100%
  );};`;
    }
  }}

  ${({ $withBarValue }) =>
    $withBarValue
      ? `& > *:not(:first-child) {
    margin-top: 1rem;
  }`
      : `& > *:not(:first-child) {
    margin-left: 1rem;
  }`};

  ${({ $withBarValue }) =>
    $withBarValue &&
    `  & > ${RowInfoLine} > *:not(:first-child) {
    margin-left: 1rem;
  };`}

  @media (min-width: 26em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
`;

const RowInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`;

const RowValue = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 1.6rem;
  }

  & > p {
    white-space: nowrap;
  }
`;

export default InfoRow;
