import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { InfoRows } from "components/Modals/styled";
import type { CurrencyInterface, AvailableTradeOptions } from "helpers/types";

interface CopyTradingRowsProps {
  currencyValue: string;
  currencies: CurrencyInterface[] | null;
  currency: AvailableTradeOptions | null;
}

const CopyTradingRows: FC<CopyTradingRowsProps> = ({
  currencyValue,
  currencies,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("YOU_SPEND")}
        value={`${normolizeCurrenciesDecimals(
          Number(currencyValue),
          currency || "USDT",
          currencies,
          "forView"
        )} USDT`}
      />

      <ModalRow keyText="Management period" value="12 month" />
      <ModalRow keyText="Reward option" value="Monthly" />
      <ModalRow keyText="Reward %" value="Floating rate" isChip isInfoChip />
      <ModalRow keyText="Fee" value="2%" />
      <ModalRow keyText="Performance commission" value="20%" />
    </InfoRows>
  );
};

export default CopyTradingRows;
