import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { CircularProgress } from "@mui/material";
import { colors } from "helpers/consts";
import { AppText } from "components";

interface PreloaderProps {
  isStatic?: boolean;
  size?: string | number;
  text?: string;
}
const Preloader: FC<PreloaderProps> = ({ isStatic, size, text }) => (
  <Wrapper $isStatic={isStatic} $withContent={!!text}>
    {isStatic ? (
      text ? (
        <ContentWrapper>
          <AppText fontWeight={600} fontSize={16}>
            {text}
          </AppText>
          <CircularProgress size={size} />
        </ContentWrapper>
      ) : (
        <CircularProgress size={size} />
      )
    ) : (
      <Spinner>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Spinner>
    )}
  </Wrapper>
);

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface PreloaderWrapperProps {
  $isStatic?: boolean;
  $withContent?: boolean;
}
const Wrapper = styled.div<PreloaderWrapperProps>`
  position: ${({ $isStatic }) => ($isStatic ? `absolute` : "fixed")};
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ $isStatic }) => ($isStatic ? `100%` : "100dvh")};
  z-index: 25;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, #000 100%); */
  background: transparent;
  ${({ $withContent }) => !$withContent && "opacity: 0.7;"};
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  & .MuiCircularProgress-root {
    color: ${colors.primary_500};
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: max-content;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_200};
  border-radius: 1.2rem;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 2rem;

  & .MuiCircularProgress-root {
    margin-top: 2rem;
  }
`;

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  div {
    animation: ${rotateAnimation} 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 4rem 4rem;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: ${colors.primary_500};
      margin: -0.4rem 0 0 -0.4rem;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 6.3rem;
      left: 6.3rem;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: 6.8rem;
      left: 5.6rem;
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: 7.1rem;
      left: 4.8rem;
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: 7.2rem;
      left: 4rem;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }

    &:nth-child(5):after {
      top: 7.1rem;
      left: 3.2rem;
    }

    &:nth-child(6) {
      animation-delay: -0.216s;
    }

    &:nth-child(6):after {
      top: 6.8rem;
      left: 2.4rem;
    }

    &:nth-child(7) {
      animation-delay: -0.252s;
    }

    &:nth-child(7):after {
      top: 6.3rem;
      left: 1.7rem;
    }

    &:nth-child(8) {
      animation-delay: -0.288s;
    }

    &:nth-child(8):after {
      top: 5.6rem;
      left: 1.2rem;
    }
  }
`;

export default Preloader;
