import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { InfoRows } from "components/Modals/styled";
import { AppText } from "components";
import type { VaucherInfo } from "helpers/types";

interface VaucherInfoProps {
  info?: VaucherInfo;
  inModal?: boolean;
}

const CardInfoRows: FC<VaucherInfoProps> = ({ info, inModal }) => {
  const { t } = useTranslation();

  return (
    <InfoRows $maxWidth={inModal ? "100%" : "30rem"}>
      {!inModal && (
        <AppText
          fontSize={18}
          fontWeight={600}
          style={{ margin: "0.4rem 0 1rem" }}
        >
          {t("CARD_INFO")}
        </AppText>
      )}

      {info?.code && (
        <ModalRow
          noMargin={!inModal}
          keyText={`${t("CODE")}:`}
          value={info?.code || ""}
        />
      )}
      {info?.voucherCurrency && (
        <ModalRow
          noMargin={!inModal}
          keyText={`${t("CURRENCY")}:`}
          value={info?.voucherCurrency || ""}
        />
      )}
      {info?.faceValue && (
        <ModalRow
          noMargin={!inModal}
          keyText={`${t("FACE_VALUE")}:`}
          value={info.faceValue || ""}
        />
      )}
      {info?.url && (
        <ModalRow noMargin={!inModal} keyText="Url:" linkUrl={info.url} />
      )}
    </InfoRows>
  );
};

export default CardInfoRows;
