import { FC } from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "@mui/material";
import { AppText } from "components";
import { colors, currencyIcons } from "helpers/consts";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-mini.svg";
import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface CurrencyButtonProps extends ButtonProps {
  icon?: string;
  value?: string;
}

const CurrencyButton: FC<CurrencyButtonProps> = ({
  icon,
  value,
  onClick,
  disabled,
}) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <OptionContent>
        {icon && (currencyIcons[icon] ? currencyIcons[icon] : <NAIcon />)}

        <AppText>{value}</AppText>
      </OptionContent>
      {!disabled && <ArrowIcon />}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 12.4rem;
  width: 100%;
  max-height: 5.2rem;
  min-height: 5.2rem;
  border-radius: 0.8rem;
  height: 100%;
  text-transform: none;
  font-weight: 500;
  font-size: 1.4rem;
  border: 1px solid ${colors.gray_100};
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(247, 144, 9, 0.1) 100%
  );
  box-shadow: none;
  color: ${colors.gray_700};

  > svg {
    margin-left: 1rem;
    min-width: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    min-height: 2rem;
    path {
      stroke: ${colors.primary_500};
    }
  }

  &:hover,
  &:focus {
    border: 1px solid ${colors.primary_500};
  }

  &:disabled {
    background-color: ${colors.gray_25};
    border: 1px solid ${colors.gray_50};
    color: ${colors.gray_300};
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > p {
    margin-left: 0.8rem;
  }

  svg {
    min-width: 3.6rem;
    max-width: 3.6rem;
  }
`;

export default CurrencyButton;
