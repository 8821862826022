import { FC, useState, MouseEvent, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Menu, MenuItem } from "@mui/material";
import { SquereButton } from "components/Buttons";
import { AppText } from "components";
import { colors } from "helpers/consts";
import { GlobalStore } from "stores";
import type { LangType } from "helpers/types";

import { ReactComponent as EnIcon } from "assets/icons/countries/en.svg";
import { ReactComponent as RuIcon } from "assets/icons/countries/ru.svg";
import { ReactComponent as EsIcon } from "assets/icons/countries/es.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

const acceptLang: LangType =
  (navigator?.language?.slice(0, 2) === "ru" && "ru") ||
  (navigator?.language?.slice(0, 2) === "es" && "es") ||
  "en";

const countryIcons = {
  en: <EnIcon />,
  ru: <RuIcon />,
  es: <EsIcon />,
};

interface Lang {
  code: LangType;
  label: string;
}
const langs: Lang[] = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "ru",
    label: "Russian",
  },
  {
    code: "es",
    label: "Spanish",
  },
];

const Language: FC = () => {
  const { lang, setLang } = GlobalStore;

  const [isLangMenuOpen, setIsLangMenuOpen] = useState<null | HTMLElement>(
    null
  );

  const handleOpenLangMenu = (e: MouseEvent<HTMLElement>) => {
    setIsLangMenuOpen(e.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setIsLangMenuOpen(null);
  };

  const changeLang = (lang: LangType) => {
    if (localStorage.getItem("lang") === lang) {
      return handleCloseLangMenu();
    }
    setLang(lang);
    localStorage.setItem("lang", lang);
    handleCloseLangMenu();
  };

  useEffect(() => {
    const existLang: string | null = localStorage.getItem("lang");
    if (existLang === "en" || existLang === "ru" || existLang === "es") {
      return setLang(existLang);
    }
    setLang(acceptLang);
    return localStorage.setItem("lang", acceptLang);
  }, [setLang]);

  return (
    <>
      <SquereButton
        onClick={handleOpenLangMenu}
        aria-controls={isLangMenuOpen ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isLangMenuOpen ? "true" : undefined}
      >
        {countryIcons[lang]}
      </SquereButton>

      <StyledMenu
        anchorEl={isLangMenuOpen}
        open={!!isLangMenuOpen}
        onClose={handleCloseLangMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {langs.map(({ code, label }) => (
          <StyledMenuItem
            key={code}
            onClick={() => changeLang(code)}
            $isActive={lang === code}
          >
            {countryIcons[code]}
            <AppText color={colors.gray_700}>{label}</AppText>
            {lang === code && (
              <Check>
                <CheckIcon />
              </Check>
            )}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

interface StyledMenuItemInterface {
  $isActive?: boolean;
}
const StyledMenuItem = styled(MenuItem)<StyledMenuItemInterface>`
  padding: 1.5rem 1.6rem;
  ${({ $isActive }) => $isActive && `background-color: ${colors.primary_25};`}

  & > p {
    margin: 0 0.8rem;
  }

  svg,
  img {
    max-width: 1.6rem;
    min-width: 1.6rem;
    max-height: 1.6rem;
    min-height: 1.6rem;
  }
`;

export const StyledMenu = styled(Menu)`
  margin-top: 0.9rem;
  .MuiMenu-paper {
    border-radius: 0.8rem;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }
  .MuiMenu-list {
    min-width: 16.4rem;
    padding: 0;
  }
`;

export const Check = styled.div`
  max-width: 2rem;
  min-width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  & > svg {
    max-width: 1.3rem;
    min-width: 1.3rem;
  }
`;

export default observer(Language);
