import { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import styled from "styled-components";
import { colors } from "helpers/consts";

interface AppTextProps extends TypographyProps {
  isSpan?: boolean;
  fontSize?: number;
  lineHeight?: number;
  margin?: string;
}

const AppText: FC<AppTextProps> = ({
  children,
  isSpan,
  noWrap = false,
  color,
  fontWeight,
  lineHeight,
  fontSize,
  ...props
}) => (
  <StyledText
    variantMapping={{ body1: "span" }}
    paragraph={!isSpan}
    noWrap={noWrap}
    color={color || colors.gray_900}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    fontSize={fontSize}
    {...props}
  >
    {children}
  </StyledText>
);

const StyledText = styled(Typography)<AppTextProps>`
  margin: ${({ margin }) => (margin ? margin : "0rem")};

  &.MuiTypography-root {
    font-weight: ${({ fontWeight }) => (fontWeight ? Number(fontWeight) : 500)};
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize / 10}rem` : "1.4rem"};
    line-height: ${({ lineHeight }) =>
      lineHeight ? `${lineHeight / 10}rem` : "1.3"};
  }
`;

export default AppText;
