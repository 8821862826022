import type { CoinsMarketsByFiat } from "api/apiTypes";

export interface MarketOptionInterface {
  id: string;
  value: {
    src: string;
    symbol: string;
    name: string;
  };
}

const returnOption = ({
  id,
  image,
  symbol,
  name,
}: CoinsMarketsByFiat): MarketOptionInterface => {
  return {
    // label: returnOptionLabel(image, symbol),
    id,
    value: {
      src: image,
      name,
      symbol,
    },
  };
};
export const mapMarketToSelectOption = (markets: CoinsMarketsByFiat[]) => {
  return markets.map(({ id, image, symbol, name }) =>
    returnOption({ id, image, symbol, name })
  );
};
