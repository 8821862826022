import styled from "styled-components";
import { colors } from "helpers/consts";
import { FC, useRef } from "react";

interface ProgressBarProps {
  value?: number;
  width?: string;
  noMargin?: boolean;
  extraValue?: number;
}

const ProgressBar: FC<ProgressBarProps> = ({
  value = 0,
  width,
  noMargin,
  extraValue,
}) => {
  const barRef = useRef<HTMLDivElement>(null);

  return (
    <BarChart $width={width} ref={barRef} $noMargin={noMargin}>
      <BarData
        $isOutOfRange={value > 100}
        style={{ width: `${value > 100 ? 100 : value}%` }}
      >
        {extraValue && (
          <ExtraBarData
            style={{
              width: `${
                extraValue > 100
                  ? 100
                  : barRef.current?.clientWidth
                  ? (extraValue / 100) * barRef.current?.clientWidth
                  : extraValue
              }%`,
            }}
          />
        )}
      </BarData>
    </BarChart>
  );
};

interface BarChartInterface {
  $width?: string;
  $noMargin?: boolean;
}
const BarChart = styled.div<BarChartInterface>`
  background-color: ${colors.gray_200};
  border-radius: 0.4rem;
  display: flex;
  height: 0.8rem;
  ${({ $width }) => $width && `max-width: ${$width}; min-width: ${$width};`}
  width: 100%;
  overflow: hidden;
  ${({ $noMargin }) => !$noMargin && `margin-top: 1.2rem;`}
`;

interface BarDataInterface {
  $isOutOfRange?: boolean;
}
const BarData = styled.div<BarDataInterface>`
  border-radius: 0.4rem;
  background-color: ${({ $isOutOfRange }) =>
    $isOutOfRange ? colors.error_500 : colors.primary_500};
  display: flex;
  justify-content: flex-end;
`;

const ExtraBarData = styled.div`
  border-radius: 0.4rem;
  background-color: ${colors.primary_600};
`;

export default ProgressBar;
