import { FC, useRef, useEffect } from "react";
import moment from "moment";
import getChartConfig from "./chartConfig";
import { createChart } from "lightweight-charts";
import { ChartDiv } from "./styled";
import type { CopyTradeInfo } from "./index";

// export type OHLCType = CandlestickData<Time> | WhitespaceData<Time>;

interface TradesChartPerops {
  data: CopyTradeInfo[];
}

const TradesChart: FC<TradesChartPerops> = ({ data }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        chart.applyOptions({ width: ref.current!.clientWidth });
      }
    };

    const chart = createChart(
      ref.current!,
      getChartConfig({ clientWidth: ref.current!.clientWidth })
    );
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      topColor: "rgba( 38, 166, 154, 0.25)",
      bottomColor: "rgba( 38, 166, 154, 0)",
      lineColor: "rgba( 38, 166, 154, 1)",
      lineWidth: 2,
    });
    newSeries.setData(
      //@ts-ignore
      data.map(({ date, return: value }) => ({
        time: moment(date, "DD/MM/YYYY").unix(),
        value: Number(value),
      }))
    );

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      if (chart) {
        chart.remove();
      }
    };
  }, [data]);

  return <ChartDiv ref={ref} />;
};

export default TradesChart;
