import { colors } from "helpers/consts";
import styled from "styled-components";

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.6rem;

  @media (min-width: 36em) {
    padding: 2rem 2.4rem;
  }
`;

export const RowContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }
`;

interface ChipViewInterface {
  $isLowerCase?: boolean;
  $isBold?: boolean;
}
export const ChipView = styled.div<ChipViewInterface>`
  display: flex;
  padding: 0.4rem 1.2rem;
  align-items: center;
  border-radius: 1.6rem;
  background: ${colors.blue_50};
  mix-blend-mode: multiply;
  color: ${colors.blue_700};
  pointer-events: none;
  font-weight: ${({ $isBold }) => ($isBold ? "600" : "500")}
    ${({ $isLowerCase }) => $isLowerCase && "text-transform: lowercase"};
`;

export const NoDataContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2.4rem 1rem;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }
`;

export const WalletActionsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;
