import { FC, useEffect, useState } from "react";

import cardPlaceholder from "assets/images/PLACEHOLDER-card.png";

interface CardImageInterface {
  src?: string;
  alt: string;
  height?: string;
  width?: string;
}
const CardImage: FC<CardImageInterface> = ({ src, alt, height, width }) => {
  const [imgSrc, setImgSrc] = useState<string>(cardPlaceholder);

  useEffect(() => {
    if (src && src !== imgSrc) {
      setImgSrc(src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={() => setImgSrc(cardPlaceholder)}
      height={height}
      width={width}
    />
  );
};

export default CardImage;
