import type { LangType } from "helpers/types";

interface ChartConfigInterface {
  clientWidth: number;
  locale?: LangType;
}
const getChartConfig = ({
  clientWidth,
  locale = "en",
}: ChartConfigInterface) => {
  return {
    height: 212,
    width: clientWidth,
    timeScale: {
      timeVisible: true,
    },
    layout: {
      fontSize: 10,
      fontFamily: "IBM Plex Sans",

      //   ...(darkTheme && {background: {color: '#222'}, textColor: '#DDD'}: {}),
    },
    // ...(darkTheme && {
    //   grid: {
    //     vertLines: {color: '#444'},
    //     horzLines: {color: '#444'},
    //   },
    // }: {}),
    rightPriceScale: {
      scaleMargins: {
        top: 0.3,
        bottom: 0.25,
      },
      borderVisible: false,
    },
    grid: {
      vertLines: {
        visible: false,
      },
    },
    localization: {
      locale,
    },
  };
};

export default getChartConfig;
