import { FC, DragEvent, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AppText } from "components";
import { colors } from "helpers/consts";

import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as DocsIcon } from "assets/icons/documents.svg";

const acceptFileTipes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "application/msword",
];

interface FileUploadInputProps {
  value: any | File | null;
  setFile: (file: any | File | null) => void;
}

const FileUploadInput: FC<FileUploadInputProps> = ({ value, setFile }) => {
  const { t } = useTranslation();

  const saveDownloadedFile = useCallback(
    (newFile: File | null) => {
      if (!newFile) {
        setFile(null);
        return;
      }

      if (!acceptFileTipes.includes(newFile.type)) {
        setFile(null);
        return;
      }

      setFile(newFile);
    },
    [setFile]
  );

  const fileDrop = useCallback(
    (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      const newFiles = e.dataTransfer.files;
      if (newFiles.length) {
        saveDownloadedFile(newFiles[0]);
      }
    },
    [saveDownloadedFile]
  );

  return (
    <>
      <UploadLabel
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={(e) => fileDrop(e)}
      >
        <ShareIcon />
        <AppText fontSize={18} fontWeight={700}>
          {t("DRAG_AND_DROP_INFO")}
        </AppText>

        <AppText fontWeight={600}>(.pdf, .doc, .jpg, .jpeg, .png)</AppText>

        <UploadInput
          onChange={({ target: { files } }) =>
            saveDownloadedFile(files?.[0] || null)
          }
        />
      </UploadLabel>
      {value && (
        <FileInfo>
          <DocsIcon />
          <AppText fontWeight={600}>{value.name}</AppText>
        </FileInfo>
      )}
    </>
  );
};

const UploadLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 16rem;
  padding: 1rem;
  max-width: 60rem;
  border-radius: 1rem;
  background-color: ${colors.primary_100};
  border: solid 0.3rem ${colors.primary_500};
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    transform: scale(1.03);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  & > p {
    color: ${colors.gray_900};
    text-align: center;
    margin-top: 0.8rem;
  }
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  max-width: 60rem;
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: ${colors.success_100};
  border: solid 0.3rem ${colors.success_500};

  & > svg {
    margin-right: 0.6rem;
  }
`;

const UploadInput = styled.input.attrs({
  type: "file",
  accept: acceptFileTipes.toString(),
})`
  display: none;
`;

export default FileUploadInput;
