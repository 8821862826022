import { FC, useState, useEffect } from "react";
import styled from "styled-components";
import SimpleBarReact from "simplebar-react";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";
import { AppText, Tooltip } from "components";
import { SquereButton } from "components/Buttons";
import type { Timer } from "helpers/types";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

interface CopyFieldProps {
  text?: string;
  withCopy?: boolean;
  info?: string;
  infoColor?: string;
  isBlured?: boolean;
  allowWrap?: boolean;
}

let timeOut: Timer;

const ReadInfo: FC<CopyFieldProps> = ({
  text,
  withCopy,
  info,
  infoColor,
  isBlured,
  allowWrap,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const closeTooltip = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (open) {
      timeOut = setTimeout(() => closeTooltip(), 1500);
    }

    return () => clearTimeout(timeOut);
  }, [open]);
  return (
    <ReadInfoConrainer
      $withInfo={info}
      $isBlured={isBlured}
      $allowWrap={allowWrap}
    >
      {info && (
        <InfoBlock $infoColor={infoColor}>
          <AppText>{info}</AppText>
        </InfoBlock>
      )}
      <SimpleBarReact
        style={{
          width: "100%",
          height: "100%",
        }}
        tabIndex={-1}
      >
        <AppText fontWeight={400}>{text || "-"}</AppText>
      </SimpleBarReact>
      {withCopy && (
        <Tooltip
          onClose={closeTooltip}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={t("COPIED")}
        >
          <SquereButton
            disabled={isBlured}
            onClick={handleCopy}
            $view="transparent"
          >
            <CopyIcon />
          </SquereButton>
        </Tooltip>
      )}
    </ReadInfoConrainer>
  );
};

interface ReadInfoConrainerInterface {
  $withInfo?: string;
  $isBlured?: boolean;
  $allowWrap?: boolean;
}
const ReadInfoConrainer = styled.div<ReadInfoConrainerInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ $withInfo }) =>
    $withInfo ? "0 0.6rem 0 0" : "0 0.6rem 0 1.6rem"};
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_100};
  border-radius: 0.8rem;
  max-height: 5.6rem;
  min-height: 5.6rem;

  & > button,
  & > div:last-of-type {
    margin-left: 1rem;
  }

  & .simplebar-content {
    flex-direction: row;

    & > p.MuiTypography-paragraph {
      width: 100%;
      text-align: ${({ $withInfo }) => ($withInfo ? "left" : "center")};
      font-size: 1.2rem;
      padding: 1.6rem 0;
      max-width: ${({ $withInfo }) =>
        $withInfo ? "calc(100% - 8rem)" : "100%"};
      ${({ $isBlured }) =>
        $isBlured && "filter: blur(4px); pointer: initial; user-select: none;"}
      white-space: ${({ $allowWrap }) => ($allowWrap ? "normal" : "nowrap")};

      @media (min-width: 36em) {
        font-size: 1.6rem;
      }
    }
  }
`;

interface InfoBlockInterface {
  $infoColor?: string;
  $isThin?: boolean;
}
export const InfoBlock = styled.div<InfoBlockInterface>`
  min-width: 8rem;
  max-width: 8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background-color: ${({ $infoColor }) =>
    $infoColor ? $infoColor : colors.gray_50};
  border: 1px solid ${colors.gray_100};
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  max-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};
  min-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};

  & > p.MuiTypography-root {
    font-size: 1.2rem;
  }

  @media (min-width: 36em) {
    min-width: 10.8rem;
    max-width: 10.8rem;
    & > p.MuiTypography-root {
      font-size: 1.4rem;
    }
  }
`;

export default ReadInfo;
