import { FC } from "react";
import { AppText, PairCoins } from "components";
import styled from "styled-components";
import { colors } from "helpers/consts";

interface PairCellProps {
  from: string;
  to: string;
  text: string;
  subText?: string;
  minWidth?: string;
}

const PairCell: FC<PairCellProps> = ({ from, to, text, subText, minWidth }) => {
  return (
    <RowPairCell $minWidth={minWidth}>
      <PairCoins from={from} to={to} />
      <RowInfoCell>
        <AppText>{text}</AppText>
        {subText && (
          <AppText isSpan color={colors.gray_600} fontWeight={400}>
            {subText}
          </AppText>
        )}
      </RowInfoCell>
    </RowPairCell>
  );
};

interface RowCoinCellProps {
  $minWidth?: string;
}
const RowPairCell = styled.div<RowCoinCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}

  & > *:not(:first-child) {
    margin-left: 1.2rem;
  }
`;

const RowInfoCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export default PairCell;
