import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { ReactComponent as ArrowIcon } from "assets/icons/chevron-down.svg";
import AppText from "./AppText";
import { colors } from "helpers/consts";

interface StyledCheckboxProps {
  label: string;
  subLabel?: string | ReactNode;
  children: ReactNode;
}

const CustomAccordion: FC<StyledCheckboxProps> = ({
  label,
  children,
  subLabel,
}) => (
  <StyledAccordion disableGutters>
    <AccordionSummary expandIcon={<ArrowIcon />}>
      {subLabel ? (
        <LineContent>
          <AppText color={colors.gray_700} fontWeight={600}>
            {label}
          </AppText>
          <AppText color={colors.gray_700} fontWeight={600}>
            {subLabel}
          </AppText>
        </LineContent>
      ) : (
        <AppText color={colors.gray_700} fontWeight={600}>
          {label}
        </AppText>
      )}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
);

const StyledAccordion = styled(Accordion)`
  border-radius: 0.8rem;
  width: 100%;
  background-color: ${colors.gray_50};
  box-shadow: none;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;

  &::before {
    height: 0;
  }

  & .MuiAccordionSummary-root {
    min-height: 4.4rem;
    border-radius: 0.8rem;
    border: solid 1px ${colors.gray_50};
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover,
    &:focus {
      border: solid 1px ${colors.primary_500};
    }
  }
  & .MuiAccordionSummary-expandIconWrapper {
    margin-left: 0.8rem;
  }
  & .MuiCollapse-root {
    background-color: ${colors.white};
  }
  &.MuiAccordion-rounded {
    border-radius: 0.8rem;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }

  & ol {
    margin-top: 1.6rem;
    padding-left: 3rem;
    display: flex;
    width: 100%;
    flex-direction: column;

    li {
      font-weight: 400;

      & > *:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`;

const LineContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default CustomAccordion;
