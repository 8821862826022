import axios from "axios";
import type {
  PaybisGetBuyCryptoPairsRes,
  PaybisGetBuyCryptoQuoteReq,
  PaybisGetBuyCryptoQuoteRes,
  PaybisInitiateSessionReq,
  PaybisInitiateSessionRes,
  PaybisGetSellCryptoPairsRes,
} from "./apiTypes";

// const paybisURL =
//   process.env.REACT_APP_ENVIRONMENT === "dev"
//     ? "https://widget-api.sandbox.paybis.com/v2"
//     : "https://widget-api.paybis.com/v2";

// const paybisConfig = {
//   headers: { Authorization: `Bearer ${process.env.REACT_APP_PAYBIS_API_KEY}` },
// };

export const paybisQuote = async (quoteData: PaybisGetBuyCryptoQuoteReq) =>
  axios.post<PaybisGetBuyCryptoQuoteRes>("/paybis/quote", quoteData);

export const getPaybisCurrencyPairs = async () =>
  axios.get<PaybisGetBuyCryptoPairsRes>("/paybis/currency/pairs/buy-crypto");

export const getPaybisSellCryptoPairs = async () =>
  axios.get<PaybisGetSellCryptoPairsRes>(`/paybis/currency/pairs/sell-crypto`);

export const initiatePaybisSession = async (data: PaybisInitiateSessionReq) =>
  axios.post<PaybisInitiateSessionRes>("/paybis/request", data);
