import { io } from "socket.io-client";

let socket = io(process.env.REACT_APP_WS_URL || "", {
  autoConnect: false,
  reconnectionDelay: 10000,
  auth: (cb) => {
    cb({
      token: localStorage.getItem("access"),
    });
  },
});

export { socket };
