import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AppText, Preloader } from "components";
import { TextInput, ReadInfo } from "components/Inputs";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { toggle2FAVerification } from "api/user";
import QRCode from "react-qr-code";
import { UserStore, GlobalStore } from "stores";
import {
  ModalHeading,
  ModalContent,
  QrContainer,
  QrZone,
  TwoFaQRContainer,
} from "./styled";
import type { Toggle2FaVerificationData } from "api/apiTypes";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

export interface TwoFaModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}

const TwoFaModal: FC<TwoFaModalProps> = ({ isOpen, onClose, url }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("");
  const {
    user: { is2FAEnabled },
    toggle2FA,
  } = UserStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    if (isLoading) {
      return;
    }
    onClose();
  }, [onClose, isLoading]);

  useEffect(() => {
    if (!isLoading && isOpen && code.length === 6) {
      setIsLoading(true);
      const reqData: Toggle2FaVerificationData = {
        oneTimePassword: code,
      };
      toggle2FAVerification(reqData)
        .then(() => {
          toggle2FA();
          closeModal();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const secretText = useMemo<string | null>(() => {
    if (is2FAEnabled || !url) {
      return null;
    }

    const searchParams = url.split("?");
    const secret = new URLSearchParams(searchParams[1]).get("secret");

    if (!secret) {
      return null;
    }

    return secret;
  }, [is2FAEnabled, url]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={() => setCode("")}
      onClose={closeModal}
    >
      <ModalContent>
        {isLoading && <Preloader isStatic />}
        <CloseModalButton onClose={closeModal} label={t("CLOSE_SELECT")} />
        <ModalHeading $centered>
          {t(is2FAEnabled ? "DISABLE" : "ENABLE")} 2FA
        </ModalHeading>
        {!is2FAEnabled && (
          <TwoFaQRContainer>
            {url && (
              <QrContainer $noBorder>
                <AppText style={{ margin: "0 0 1rem" }}>
                  {t("SCAN_QR_BY_AUTH_APP")}
                </AppText>
                <QrZone $isLarge>
                  <QRCode value={url} size={170} />
                </QrZone>
              </QrContainer>
            )}
            {secretText && (
              <>
                <AppText style={{ margin: "1rem 0" }}>
                  {t("MANUALY_ENTER_TO_AUTH_APP")}
                </AppText>
                <ReadInfo text={secretText} withCopy />
              </>
            )}
          </TwoFaQRContainer>
        )}

        <AppText
          style={{ margin: is2FAEnabled ? "1rem 0 2rem" : "2rem 0 1rem" }}
        >
          {t("ENTER_CODE_FROM_AUTH_APP")}
        </AppText>

        <TextInput
          value={code}
          onChange={({ target: { value } }) => setCode(value)}
          leftIcon={<LockIcon />}
          placeholder={t("CODE")}
          maxWidth="24rem"
          autoComplete="off"
        />
      </ModalContent>
    </BasicModal>
  );
};

export default observer(TwoFaModal);
