import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
} from "../styled";

import stakeImg from "assets/images/stake_success.jpg";
import { ReactComponent as HourGlasskIcon } from "assets/icons/hourglass-status.svg";

interface SuccessDepositStepProps {
  onClose: () => void;
}

const SuccessDepositStep: FC<SuccessDepositStepProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={stakeImg} alt="decorative" />
          <StatusIconContainer $isPending>
            <HourGlasskIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn style={{ margin: "1rem 0 1.6rem" }}>
            <AppText fontSize={16} fontWeight={600} color={colors.gray_700}>
              {t("DEPOSIT")} {t("INITIATED")}
            </AppText>
          </InfoColumn>

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessDepositStep;
