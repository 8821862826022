import axios from "axios";
import type {
  TradeInterface,
  GetExchangeOrdersInterface,
  ExchangeOrdersResponseInterface,
} from "./apiTypes";

export const trade = async (tradeData: TradeInterface) =>
  axios.post("/exchange/order/open", tradeData);

export const getExchangeOrders = async (data: GetExchangeOrdersInterface) =>
  axios.get<ExchangeOrdersResponseInterface>("/exchange/order/list", {
    params: { ...data },
  });

export const closeOrder = async (orderId: string) =>
  axios.post(`/exchange/order/${orderId}/cancel`);
