import { FC, ReactNode } from "react";
import { Switch, SwitchProps, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { colors, mainFont } from "helpers/consts";
import { AppText } from "components";

interface StyledCheckboxProps extends SwitchProps {
  label?: string | ReactNode;
  inHeader?: boolean;
  maxWidth?: number;
}

const StyledSwitch: FC<StyledCheckboxProps> = ({
  label,
  style,
  inHeader,
  maxWidth,
  ...props
}) => (
  <>
    <StyledFormControl
      $inHeader={inHeader}
      control={<Switch {...props} />}
      label={
        <AppText noWrap maxWidth={maxWidth ? maxWidth : undefined}>
          {label || ""}
        </AppText>
      }
      style={style}
    />
  </>
);

interface StyledFormControlInterface {
  $inHeader?: boolean;
}
const StyledFormControl = styled(FormControlLabel)<StyledFormControlInterface>`
  margin: 0;
  padding-right: ${({ $inHeader }) => ($inHeader ? "1.6rem" : "2.4rem")};
  border-radius: 0.8rem;
  min-height: ${({ $inHeader }) => ($inHeader ? "4rem" : "4.4rem")};
  max-height: ${({ $inHeader }) => ($inHeader ? "4rem" : "4.4rem")};
  border: 1px solid ${colors.gray_300};

  .MuiSwitch-track {
    background-color: ${colors.gray_600};
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${colors.primary_500};
  }

  .MuiSwitch-thumb {
    background-color: ${colors.gray_50};
  }
  .Mui-checked .MuiSwitch-thumb {
    background-color: ${colors.primary_500};
  }
  .MuiTypography-root {
    font-size: 1.4rem;
    font-family: ${mainFont};
    color: ${colors.gray_600};
    font-weight: 500;
    line-height: 1.2;
  }
`;

export default StyledSwitch;
