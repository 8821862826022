import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { InfoRows } from "../styled";

interface UnstakeInfoRowsProps {
  fees: number;
  currency: string;
}

const UnstakeInfoRows: FC<UnstakeInfoRowsProps> = ({ fees, currency }) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("UNSTACKING_FEE")}
        value={`${fees} ${currency}`}
        isBold
      />
    </InfoRows>
  );
};

export default UnstakeInfoRows;
