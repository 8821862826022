import { Button } from "@mui/material";
import styled from "styled-components";
import { colors } from "helpers/consts";

const ModalButton = styled(Button)`
  padding: 1.3rem 2.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  min-height: 6rem;
  text-transform: none;
  font-weight: 600;
  font-size: 1.8rem;
  background-color: ${colors.primary_500};
  border: 1px solid ${colors.primary_500};
  color: ${colors.white};
  box-shadow: none;

  &:disabled {
    background-color: ${colors.gray_100};
    border: 1px solid ${colors.gray_200};
    color: ${colors.white};
  }

  &:hover,
  &:focus {
    background-color: ${colors.primary_600};
    border: 1px solid ${colors.primary_600};
    box-shadow: none;
  }
`;

export default ModalButton;
