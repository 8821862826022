import { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import styled from "styled-components";
import { colors } from "helpers/consts";

interface HeadingProps extends TypographyProps {
  fontSize?: number;
  lineHeight?: number;
  margin?: string;
  variant?: "h2" | "h3" | "h4";
}

const Heading: FC<HeadingProps> = ({
  children,
  noWrap = false,
  color,
  fontWeight,
  lineHeight,
  fontSize,
  variant,
  ...props
}) => (
  <StyledHeading
    noWrap={noWrap}
    color={color || colors.gray_700}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    fontSize={fontSize}
    variant={variant || "h2"}
    {...props}
  >
    {children}
  </StyledHeading>
);

const StyledHeading = styled(Typography)<HeadingProps>`
  margin: ${({ margin }) => (margin ? margin : "0rem")};

  &.MuiTypography-root {
    font-weight: ${({ fontWeight }) => (fontWeight ? Number(fontWeight) : 600)};
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize / 10}rem` : "1.6rem"};
    line-height: ${({ lineHeight }) =>
      lineHeight ? `${lineHeight / 10}rem` : "1.3"};
  }
`;

export default Heading;
