import { FC, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { currencyIcons } from "helpers/consts";
import { AppText, Chip, Heading } from "components";
import {
  MainContainer,
  TopContent,
  HeadingContent,
  LineContent,
  ChartContent,
} from "./styled";
import Chart from "./Chart";
import { CopyTradeRes } from "api/apiTypes";
import { getCopyTradingStats } from "api/coinInfo";
import { GlobalStore } from "stores";

export interface CopyTradeInfo {
  date: string;
  return: number;
}

const CopyTradeChart: FC = () => {
  const [copyTradeData, setCopyTradeData] = useState<CopyTradeRes[] | null>(
    null
  );
  const [shouldChartRender] = useState<boolean>(true);

  useEffect(() => {
    getCopyTradingStats()
      .then((data) => data.json())
      .then((data) => setCopyTradeData(data))
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      });
  }, []);
  //@ts-ignore
  const filteredInfo = useMemo<CopyTradeInfo[]>(() => {
    if (copyTradeData) {
      return copyTradeData.filter((el) => el.return);
    }
    return [];
  }, [copyTradeData]);

  const priceDiff = useMemo<number>(() => {
    if (filteredInfo.length > 1) {
      return Number(
        (
          ((filteredInfo.at(-1)!.return - filteredInfo.at(-2)!.return) /
            filteredInfo.at(-2)!.return) *
          100
        ).toFixed(2)
      );
    }

    return 0;
  }, [filteredInfo]);

  return (
    <MainContainer>
      <TopContent>
        <HeadingContent>
          <Heading>Portfolio value</Heading>
          <LineContent>
            <AppText fontSize={30} lineHeight={38} fontWeight={600}>
              USDC{" "}
              {filteredInfo.at(-1)?.return
                ? `${filteredInfo.at(-1)?.return}%`
                : "-"}
            </AppText>
            {priceDiff !== 0 && (
              <Chip isSuccess={priceDiff >= 0} isLarge withBorder withArrow>
                {priceDiff}%
              </Chip>
            )}
          </LineContent>
        </HeadingContent>
        {currencyIcons.USDC}
      </TopContent>
      {shouldChartRender && (
        <ChartContent>
          <Chart data={filteredInfo} />
        </ChartContent>
      )}
    </MainContainer>
  );
};

export default observer(CopyTradeChart);
