import { FC, CSSProperties, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppText, Heading } from "components";
import { colors } from "helpers/consts";

interface WarningProps {
  text?: string;
  heading?: string;
  component?: ReactNode;
  withPadding?: boolean;
  withMargin?: boolean;
  style?: CSSProperties;
  extraNode?: ReactNode;
  capitalizeHeading?: boolean;
}
const Warning: FC<WarningProps> = ({
  text = "",
  heading,
  component,
  withPadding,
  withMargin,
  extraNode,
  capitalizeHeading,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <MainContainer
      $withPadding={withPadding}
      style={style || {}}
      $withMargin={withMargin}
    >
      <WarningContainer>
        <WarningContent>
          <StaticWarningInfo>
            <Heading
              variant="h3"
              style={{
                marginBottom: "0.8rem",
                textTransform: capitalizeHeading ? "capitalize" : "uppercase",
              }}
            >
              {heading ? heading : `${t("WARNING")}:`}
            </Heading>
            {component ? (
              component
            ) : (
              <AppText color={colors.gray_700}>{text}</AppText>
            )}
          </StaticWarningInfo>
        </WarningContent>
        {extraNode ? extraNode : null}
      </WarningContainer>
    </MainContainer>
  );
};

interface WarningContainerInterface {
  $withPadding?: boolean;
  $withMargin?: boolean;
}

const MainContainer = styled.div<WarningContainerInterface>`
  width: 100%;
  padding: ${({ $withPadding }) => ($withPadding ? "0 1.6rem" : "0 0")};
  ${({ $withMargin }) => $withMargin && "margin-bottom: 1rem;"}

  @media (min-width: 34em) {
    padding: ${({ $withPadding }) => ($withPadding ? "0 2.4rem" : "0 0")};
    ${({ $withMargin }) => $withMargin && "margin-bottom: 1.6rem;"}
  }
`;
const WarningContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: ${colors.error_50};
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 1.8rem;
  border: solid 1px ${colors.primary_200};

  & > button {
    min-width: 18rem;
    margin: 0.8rem 0 0 0;
  }

  @media (min-width: 34em) {
    padding: 1.6rem;
    flex-wrap: nowrap;
    flex-direction: row;

    & > button {
      margin: 0 0 0 1.6rem;
    }
  }
`;

const WarningContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const StaticWarningInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Warning;
