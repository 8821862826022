import {
  FC,
  useCallback,
  useState,
  FormEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  CloseModalButton,
  BackModalButton,
  ModalButton,
} from "components/Buttons";
import BasicModal from "./BasicModal";
import { TextInput } from "components/Inputs";
import { colors } from "helpers/consts";
import {
  ModalHeading,
  Form,
  InputContainer,
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  InfoColumn,
} from "./styled";
import { AppText, Preloader } from "components";
import { activatePhysicalCard } from "api/creditco";
import { GlobalStore } from "stores";
import type { CredictcoCardDetails } from "helpers/types";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface CardActivateModalProps {
  cardId?: string;
  isOpen: boolean;
  onClose: () => void;
  setCardInfo: Dispatch<SetStateAction<CredictcoCardDetails | null>>;
  cardInfo: CredictcoCardDetails | null;
}
const CardActivateModal: FC<CardActivateModalProps> = ({
  cardId,
  onClose,
  isOpen,
  setCardInfo,
  cardInfo,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [activateCode, setActivateCode] = useState<string>("");

  const activateCard = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!activateCode || !cardId) {
        return;
      }
      setIsLoading(true);

      activatePhysicalCard(cardId, activateCode)
        .then(() => {
          setIsActivated(true);
          if (cardInfo) {
            setCardInfo({
              ...cardInfo,
              isActivated: true,
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
      setIsLoading(false);
    },
    [activateCode, cardId, setCardInfo, cardInfo]
  );

  const resetState = useCallback(() => {
    setActivateCode("");
  }, []);

  return (
    <BasicModal onExited={resetState} isOpen={isOpen} onClose={onClose}>
      {!isActivated ? (
        <Form onSubmit={activateCard}>
          <BackModalButton onBack={onClose} label="back" />
          <CloseModalButton onClose={onClose} label="close" />
          {isLoading && <Preloader isStatic />}
          <ModalHeading $centered>{t("CARD_ACTIVATION")}</ModalHeading>

          <InputContainer $marginTop>
            <AppText>{t("ENTER_CODE_TO_ACTIVATE_CARD")}</AppText>
            <TextInput
              placeholder={t("ENTER_CODE")}
              value={activateCode}
              onChange={({ target: { value } }) => setActivateCode(value)}
              leftIcon={<LockIcon />}
              autoComplete="off"
            />
          </InputContainer>

          <ModalButton disabled={!activateCode} type="submit">
            {t("ACTIVATE")}
          </ModalButton>
        </Form>
      ) : (
        <StatusContent style={{ marginTop: "4rem" }}>
          <BackModalButton onBack={onClose} label="back" />
          <CloseModalButton onClose={onClose} label="close" />
          <StatusDecorative $isTransparent>
            <StatusIconContainer>
              <CheckIcon />
            </StatusIconContainer>
          </StatusDecorative>

          <InfoColumn>
            <AppText
              style={{ marginTop: "1.4rem" }}
              fontSize={18}
              fontWeight={600}
              color={colors.gray_700}
            >
              {t("SUCCESS_CARD_ACTIVE")}
            </AppText>

            <ModalButton style={{ marginTop: "1.4rem" }} onClick={onClose}>
              {t("CONTINUE")}
            </ModalButton>
          </InfoColumn>
        </StatusContent>
      )}
    </BasicModal>
  );
};

export default observer(CardActivateModal);
