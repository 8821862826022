import { FC } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";

import { ReactComponent as BackIcon } from "assets/icons/arrow-narrow-left.svg";

interface BackModalButtonProps {
  onBack: () => void;
  label?: string;
}

const BackModalButton: FC<BackModalButtonProps> = ({ onBack, label }) => {
  const { t } = useTranslation();
  return (
    <BackButton aria-label={label ? label : t("GO_BACK")} onClick={onBack}>
      <BackIcon />
    </BackButton>
  );
};

export const BackButton = styled(Button)`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  min-width: 4.4rem;
  max-width: 4.4rem;
  min-height: 4.4rem;
  max-height: 4.4rem;
  color: ${colors.gray_600};
  background: transparent;
  border: none;
  position: absolute;
  top: 1.6rem;
  left: 0.8rem;

  &:hover,
  &:focus {
    border: none;
  }

  > svg {
    min-width: 2.4rem;
    max-width: 2.4rem;
    min-height: 2.4rem;
    max-height: 2.4rem;
    path {
      stroke: ${colors.gray_500};
    }
  }

  &:focus:active {
    box-shadow: 0px 0px 0px 4px ${colors.gray_100},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`;

export default BackModalButton;
