import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { InfoRows } from "../styled";
import { CurrenciesStore } from "stores";

interface WithdrawInfoRowsProps {
  currencyValue: string;
  fees: number;
}

const MetaMaskInfoRows: FC<WithdrawInfoRowsProps> = ({
  currencyValue,
  fees,
}) => {
  const { t } = useTranslation();
  const { currencies } = CurrenciesStore;

  return (
    <Accordion
      label={t("TOTAL_TO_RECEIVE")}
      subLabel={toLocaleStringWithCurrency(
        Number(currencyValue) - fees > 0 ? Number(currencyValue) - fees : 0,
        "ETH",
        currencies
      )}
    >
      <InfoRows>
        <ModalRow
          keyText={t("FEES")}
          value={toLocaleStringWithCurrency(fees, "ETH", currencies)}
        />
      </InfoRows>
    </Accordion>
  );
};

export default observer(MetaMaskInfoRows);
