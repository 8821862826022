import { Button } from "@mui/material";
import styled from "styled-components";
import { colors } from "helpers/consts";

interface SquereButtonProps {
  $view?: "transparent" | "delete" | "primary" | "forInput";
  $toFillSvg?: boolean;
}

const SquereButton = styled(Button)<SquereButtonProps>`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  color: ${colors.gray_600};
  ${({ $view, $toFillSvg }) => {
    if ($view === "transparent") {
      return `
        background: transparent;
        border: none;

        &:hover,
        &:focus {
          border: none;
        }

        &:disabled {
          opacity: 0.4;
        }
    `;
    }
    if ($view === "delete") {
      return `
        background-color: ${colors.error_500};
        border: 1px solid ${colors.error_500};

        > svg path {
          stroke: ${colors.white};
        }

        &:hover,
        &:focus {
           background-color: ${colors.error_600};
           border: 1px solid ${colors.error_600};
        }

        &:disabled {
          background-color: ${colors.gray_200};
          border: 1px solid ${colors.gray_200};
        }
    `;
    }
    if ($view === "primary") {
      return `
        background-color: ${colors.primary_50};
        border: 1px solid ${colors.primary_200};
        min-width: 4.4rem;
        max-width: 4.4rem;
        min-height: 4.4rem;
        max-height: 4.4rem;

                svg path {
          ${
            $toFillSvg
              ? `fill: ${colors.primary_600};`
              : `stroke: ${colors.primary_600};`
          }
        }

        &:hover,
        &:focus {
           background-color: ${colors.primary_200};
        }

        &:disabled {
          background-color: ${colors.gray_100};
          border: 1px solid ${colors.gray_300};

          svg path {
            ${
              $toFillSvg
                ? `fill: ${colors.gray_300};`
                : `stroke: ${colors.gray_300};`
            }
          }
        }
    `;
    }
    if ($view === "forInput") {
      return `
        border: 1px solid ${colors.gray_300};
        min-width: 4.3rem;
        max-width: 4.3rem;
        min-height: 4.3rem;
        max-height: 4.3rem;

                svg path {
          ${
            $toFillSvg
              ? `fill: ${colors.gray_600};`
              : `stroke: ${colors.gray_600};`
          }
        }

        &:hover,
        &:focus {
          background-color: ${colors.white};
          border: 1px solid ${colors.primary_500};
        }

        &:disabled {
          background-color: ${colors.gray_100};
          border: 1px solid ${colors.gray_300};

          svg path {
            ${
              $toFillSvg
                ? `fill: ${colors.gray_300};`
                : `stroke: ${colors.gray_300};`
            }
          }
        }
    `;
    }

    return `
        background-color: ${colors.white};
        border: 1px solid ${colors.gray_200};

        &:hover,
        &:focus {
          border: 1px solid ${colors.gray_300};
          box-shadow: 0px 0px 0px 4px ${colors.gray_100},
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          background-color: ${colors.gray_200};
          border: 1px solid ${colors.gray_200};
        }
    `;
  }}

  > svg {
    min-width: 2rem;
    max-width: 2rem;
  }

  &:focus:active {
    box-shadow: 0px 0px 0px 4px ${colors.gray_100},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`;

export default SquereButton;
