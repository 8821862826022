import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "translations/en";
import ru from "translations/ru";
import es from "translations/es";

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
