import axios from "axios";
import {
  UserStore,
  GlobalStore,
  CardsStore,
  StakesStore,
  WalletStore,
} from "stores";
import { notVerifiedMessage, twoFAMessage } from "helpers/consts";
import { token } from "api/user";
import type { UserCompanyData } from "helpers/types";

export const companyLogIn = async () => {
  GlobalStore.startLoading();
  return axios
    .post("/company/login")
    .then(({ data }) => {
      if (data?.emailVerified === false) {
        GlobalStore.setError("email not verified");
        return notVerifiedMessage;
      }
      token.setCompanyToken(data.token);
      UserStore.setStatistics(null);
      UserStore.setRequestedForms(null);
      CardsStore.clearCardsStore();
      StakesStore.setActiveStakes(null);
      WalletStore.setWalletsList(null);
      UserStore.setAuthenticated(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message === twoFAMessage) {
        return twoFAMessage;
      }
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    })
    .finally(() => GlobalStore.endLoading());
};

export const getUsersCompanyInfo = async () =>
  axios
    .get<UserCompanyData>("/user/company")
    .then(({ data }) => {
      UserStore.setUserCompanyInfo(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        console.log(err.response.data.message);
      }
    });
