import styled from "styled-components";
import { colors } from "helpers/consts";

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .MuiTableContainer-root {
    margin-top: auto;
    border-top: none;
  }
`;

export const TopModalContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2.4rem;
  border-bottom: 1px solid ${colors.gray_200};
`;

export const TopInfoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  gap: 1.6rem;
  margin-top: 2.4rem;
  padding: 0 1.6rem;

  @media (min-width: 36em) {
    padding: 0;
  }

  @media (min-width: 42em) {
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
  }

  @media (min-width: 56em) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  border: 1px solid ${colors.gray_200};
  background-color: ${colors.white};
  padding: 2.4rem;
  width: 100%;
  overflow: hidden;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 1.6rem;
  }
`;

export const MainModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
`;

export const InfoColumns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:not(:first-child) {
    margin-top: 2.4rem;
  }

  @media (min-width: 48em) {
    flex-direction: row;
    flex-wrap: nowrap;

    & > *:not(:first-child) {
      margin-top: 7.2rem;
    }
  }
`;

export const InfoColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:not(:first-child) {
    margin-top: 2.4rem;
  }
`;
