import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
// import { MetaMaskProvider } from "@metamask/sdk-react";

import App from "./App";
import { AppStyles } from "./App.styled";
// import { siteName } from "helpers/consts";
import "simplebar-react/dist/simplebar.min.css";
import "api/apiConfig";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://8bbd2831ed666d1cec05526526e78ee0@o4505345254162432.ingest.sentry.io/4506468488445952",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "portal.fntworld.io"],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

polyfillCountryFlagEmojis();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    {/* <MetaMaskProvider
      debug={false}
      sdkOptions={{
        dappMetadata: {
          name: siteName,
          url: window.location.origin,
        },
      }}
    > */}
    <BrowserRouter>
      <App />
      <AppStyles />
    </BrowserRouter>
    {/* </MetaMaskProvider> */}
  </LocalizationProvider>
);
