import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { ModalRow } from "components/Rows";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
} from "components/Buttons";
import { CardImage } from "components";
import {
  ModalContent,
  StatusDecorative,
  InfoColumn,
  ModalHeading,
} from "../styled";
import { InfoRows } from "components/Modals/styled";
import type { AvailableTradeOptions } from "helpers/types";

interface ConfirmPurchaseStepProps {
  cardImage: string;
  brandName: string;
  chosenCurrency: string;
  onClose: () => void;
  onSubmit: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  isCrypto?: boolean;
  cashbackPercent?: string;
  TransactionInfos: ReactNode;
}

const ConfirmPurchaseStep: FC<ConfirmPurchaseStepProps> = ({
  onClose,
  currencyValue,
  currency,
  cardImage,
  brandName,
  chosenCurrency,
  onSubmit,
  isCrypto,
  cashbackPercent,
  TransactionInfos,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <ModalHeading $centered>
          {t(isCrypto ? "CRYPTO_CARD_REVIEW" : "GIFT_CARD_REVIEW")}
        </ModalHeading>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onClose} />

        <StatusDecorative $noIcon>
          <CardImage src={cardImage} alt={brandName} />
        </StatusDecorative>

        <InfoColumn>
          <InfoRows>
            <ModalRow
              keyText={t("CARD_PRICE")}
              value={`${currencyValue} ${chosenCurrency}`}
            />
            {currency && (
              <ModalRow
                keyText={t("ASSET_OR_PAYMENT_METHOD")}
                value={currency}
              />
            )}
            {cashbackPercent && Number(cashbackPercent) > 0 && (
              <ModalRow
                keyText={t("CASHBACK")}
                value={`${Number(cashbackPercent) * 100}%`}
              />
            )}
          </InfoRows>
          {TransactionInfos}

          <ModalButton onClick={onSubmit}>{t("CONFIRM")}</ModalButton>
        </InfoColumn>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmPurchaseStep;
