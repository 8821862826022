import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useMemo,
  FormEvent,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { WalletStore, StakesStore } from "stores";
import {
  ModalButton,
  CloseModalButton,
  SquereButton,
} from "components/Buttons";
import { CurrencyInput, RangeInput, Select } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { CoinCell } from "components/Table";
import { CurrencySelectModal } from "components/Modals";
import StakeInfoRows from "./StakeInfoRows";
import { AppText, Tabs } from "components";
import {
  toLocaleStringWithCurrency,
  normolizeCurrenciesDecimals,
} from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  MainContainer,
  TransactionGroup,
  InfoLine,
  ModalError,
  ModalErrorStatus,
  TradeInfo,
  UnstakeLine,
  UnstakeColumn,
} from "../styled";
import type {
  StakeType,
  CurrencyInterface,
  StakeStrategyInterface,
  AvailableTradeOptions,
  StakeInterface,
} from "helpers/types";

import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const tabs = ["STAKE", "UNSTAKE"];

const exceedAmount = "ERROR_NO_AMOUNT";
const lowerThanMinAmount = "ERROR_MIN_AMOUNT";

interface MainStakeStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
  stakeValue: string;
  strategy: StakeStrategyInterface | null;
  setStakeValue: Dispatch<SetStateAction<string>>;
  setFullStakeValue: Dispatch<SetStateAction<string>>;
  setStrategy: Dispatch<SetStateAction<StakeStrategyInterface | null>>;
  stakeType: StakeType;
  currencies: CurrencyInterface[] | null;
  tabValue: number;
  setTabValue: Dispatch<SetStateAction<number>>;
  cost: number;
  onUnstakeSubmit: (activeStake?: StakeInterface) => void;
  isLoading: boolean;
}

const MainStakeStep: FC<MainStakeStepProps> = ({
  onSubmit,
  onClose,
  stakeValue,
  strategy,
  setStakeValue,
  setFullStakeValue,
  setStrategy,
  stakeType,
  currencies,
  tabValue,
  setTabValue,
  onUnstakeSubmit,
  cost,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { walletsList } = WalletStore;
  const { stakeSrategiesCurrencies, activeStakes, stakeSrategies } =
    StakesStore;
  const [hasError, setHasError] = useState<string | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  const currencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => strategy?.currencyName === currencyName
    )?.balance;

    return !strategy?.currencyName || !leftBalance
      ? 0
      : Number(
          normolizeCurrenciesDecimals(
            leftBalance,
            strategy?.currencyName || "USDT",
            currencies
          ).replaceAll(",", "")
        );
  }, [walletsList, strategy?.currencyName, currencies]);

  const fullCurrencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => strategy?.currencyName === currencyName
    )?.balance;
    return !strategy?.currencyName || !leftBalance ? 0 : Number(leftBalance);
  }, [walletsList, strategy?.currencyName]);

  const stakedAlreadyCurr = useMemo<string>(() => {
    if (!strategy?.currencyName || !activeStakes) {
      return "0";
    }
    const stakeSum = activeStakes.reduce((acc: number, el: StakeInterface) => {
      if (el.currencyName === strategy?.currencyName) {
        acc += Number(el.amount);
        return acc;
      }
      return acc;
    }, 0);

    return normolizeCurrenciesDecimals(
      stakeSum,
      strategy.currencyName,
      currencies,
      "forView"
    );
  }, [activeStakes, strategy?.currencyName, currencies]);

  const checkIfError = (value: number) => {
    if (hasError) {
      setHasError(null);
    }
    if (!value) {
      return;
    }
    if (stakeType === "stake" && Number(value) > fullCurrencyBalance) {
      setHasError(t(exceedAmount));
    }

    if (strategy && Number(value) < Number(strategy.minAmount)) {
      setHasError(
        `${t(lowerThanMinAmount)} - ${strategy.minAmount} ${
          strategy.currencyName
        }`
      );
    }
  };

  const handleChangeValue = (
    value: string,
    shouldNotChangeMain?: "noMainChange"
  ) => {
    const correctInvalidValue = value[0] === "." ? `0${value}` : value;

    if (!shouldNotChangeMain) {
      setFullStakeValue(correctInvalidValue);
    }
    setStakeValue(value);
    checkIfError(Number(value));
  };

  const onCurrencyChange = (option: AvailableTradeOptions) => {
    const newStrategy = stakeSrategies?.find(
      ({ currencyName }) => currencyName === option
    );
    if (!newStrategy) {
      return;
    }
    setStrategy(newStrategy);
  };

  const currencyStrategies = useMemo<SelectItemProps[]>(() => {
    if (!stakeSrategies || !strategy) {
      return [];
    }
    const availableActiveStakes = stakeSrategies.filter(
      ({ currencyName }) => currencyName === strategy?.currencyName
    );

    if (availableActiveStakes.length > 2) {
      return [];
    }
    return availableActiveStakes.map(({ id, payoutMax, payoutMin }) => {
      return {
        value: String(id),
        key: String(id),
        label: `APY ${payoutMax ? payoutMax : payoutMin || 0}%`,
      };
    });
  }, [stakeSrategies, strategy]);

  const onStrategyChange = useCallback(
    (strategyId: string) => {
      const newStrategy = stakeSrategies?.find(
        ({ id }) => id === Number(strategyId)
      );
      if (!newStrategy) {
        return;
      }
      setStrategy(newStrategy);
    },
    [setStrategy, stakeSrategies]
  );

  useEffect(() => {
    handleChangeValue(stakeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, stakeType]);

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <ModalHeading>
          {stakeType === "stake" ? t("STAKE") : t("UNSTAKE")}
        </ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <MainContainer>
            <Tabs
              tabs={tabs}
              value={tabValue}
              setValue={setTabValue}
              tabsLabel="stake-tabs"
              isModal
            />

            {stakeType === "stake" ? (
              <>
                {currencyStrategies?.length > 1 && (
                  <TransactionGroup>
                    <AppText color={colors.gray_500}>Stake strategy</AppText>

                    <Select
                      value={String(strategy?.id) || ""}
                      setValue={(value) => onStrategyChange(value)}
                      variants={currencyStrategies}
                      isStyled
                    />
                  </TransactionGroup>
                )}
                <TransactionGroup>
                  <TradeInfo>
                    <AppText color={colors.gray_500}>
                      {stakeType === "stake"
                        ? t("AMOUNT_TO_STAKE")
                        : t("AMOUNT_TO_UNSTAKE")}
                    </AppText>
                    {strategy && (
                      <AppText fontSize={13} color={colors.gray_500}>
                        min {strategy.minAmount} {strategy.currencyName}
                      </AppText>
                    )}
                  </TradeInfo>

                  <CurrencyInput
                    value={stakeValue}
                    setValue={(value) => handleChangeValue(value)}
                    currency={strategy?.currencyName || null}
                    onClick={openSelect}
                    currencies={currencies}
                    placeholder={
                      strategy?.minAmount ? `min ${strategy.minAmount}` : "0"
                    }
                  />
                  <InfoLine>
                    <WalletIcon />
                    <AppText
                      color={colors.gray_600}
                      fontWeight={400}
                      fontSize={12}
                    >
                      {t("BALANCE")}
                      {` ${currencyBalance} ${
                        strategy?.currencyName || ""
                      } / ${t("STAKED")} ${stakedAlreadyCurr} ${
                        strategy?.currencyName || ""
                      }`}
                    </AppText>
                  </InfoLine>
                </TransactionGroup>

                <RangeInput
                  value={stakeValue}
                  setValue={handleChangeValue}
                  currencies={currencies}
                  balance={currencyBalance}
                  mainCurrency={strategy?.currencyName || null}
                  setFullValue={setFullStakeValue}
                />
              </>
            ) : (
              <UnstakeColumn>
                {activeStakes && activeStakes.length > 0 ? (
                  activeStakes?.map((activeStake) => {
                    const currentStrategy = stakeSrategies?.find(
                      ({ id }) => id === activeStake.strategyId
                    );
                    return (
                      <UnstakeLine key={activeStake.uuid}>
                        <CoinCell
                          coin={activeStake.currencyName}
                          text={`${normolizeCurrenciesDecimals(
                            activeStake.amount,
                            activeStake.currencyName,
                            currencies
                          )} ${activeStake.currencyName}${
                            currentStrategy
                              ? ` (${t("REWARD")}: APY ${
                                  currentStrategy.payoutMax
                                    ? currentStrategy.payoutMax
                                    : currentStrategy.payoutMin || 0
                                }%)`
                              : ""
                          }`}
                        />

                        <SquereButton
                          $view="delete"
                          onClick={() => onUnstakeSubmit(activeStake)}
                        >
                          <CloseIcon />
                        </SquereButton>
                      </UnstakeLine>
                    );
                  })
                ) : (
                  <AppText fontSize={16}>{t("NO_ACTIVE_STAKES")}</AppText>
                )}
              </UnstakeColumn>
            )}
          </MainContainer>

          {stakeType === "stake" && (
            <>
              <StakeInfoRows
                stakeValue={stakeValue}
                strategy={strategy}
                currencies={currencies}
                cost={cost}
              />
              {hasError && (
                <ModalError>
                  <ModalErrorStatus>
                    <AppText fontSize={12} color={colors.error_700}>
                      {t("WARNING")}
                    </AppText>
                  </ModalErrorStatus>
                  <AppText fontSize={12} color={colors.error_700}>
                    {hasError}
                  </AppText>
                </ModalError>
              )}
              <ModalButton
                disabled={Number(stakeValue) <= 0 || !!hasError || isLoading}
                type="submit"
              >
                {t("STAKE")}{" "}
                {Number(stakeValue) > 0
                  ? toLocaleStringWithCurrency(
                      Number(stakeValue),
                      strategy?.currencyName || "USDT",
                      currencies
                    )
                  : ""}
              </ModalButton>
            </>
          )}
        </InnerContentForm>
        <CurrencySelectModal
          onClose={closeSelect}
          isOpen={isSelectOpen}
          selected={strategy?.currencyName || null}
          options={stakeSrategiesCurrencies || []}
          setValue={onCurrencyChange}
          isInsideModal
        />
      </ModalContent>
    </Fade>
  );
};

export default observer(MainStakeStep);
