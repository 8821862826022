import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { MainButtonProps, StyledMainButton } from "./MainButton";
import { Tooltip } from "components";
import type { Timer } from "helpers/types";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

interface CopyButtonProps extends MainButtonProps {
  copy: string;
  label?: string;
}
let timeOut: Timer;

const CopyButton: FC<CopyButtonProps> = ({
  label,
  copy,
  maxWidth,
  fontSize,
  fullWidth,
  toFillSvg,
  lineHeight,
  variant,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const closeTooltip = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(copy);
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      timeOut = setTimeout(() => closeTooltip(), 1500);
    }

    return () => clearTimeout(timeOut);
  }, [open]);

  return (
    <Tooltip
      onClose={closeTooltip}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={t("COPIED")}
    >
      {variant === "text" ? (
        <StyledMainButton
          onClick={handleCopy}
          variant="text"
          $forTable
          $maxWidth={maxWidth}
          $fontSize={fontSize}
          $fullWidth={fullWidth}
          $toFillSvg={toFillSvg}
          $lineHeight={lineHeight}
        >
          {children}
        </StyledMainButton>
      ) : (
        <StyledMainButton
          onClick={handleCopy}
          {...otherProps}
          $maxWidth={maxWidth}
          $fontSize={fontSize}
          $fullWidth={fullWidth}
          $toFillSvg={toFillSvg}
          $lineHeight={lineHeight}
        >
          <CopyIcon />
          {label ? label : t("COPY")}
        </StyledMainButton>
      )}
    </Tooltip>
  );
};

export default CopyButton;
