import axios from "axios";
import { UserStore, GlobalStore } from "stores";
import { checkIsLogged } from "api/user";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

if (localStorage.getItem("companyAccess")) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("companyAccess")}`;
}
if (!localStorage.getItem("companyAccess") && localStorage.getItem("access")) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access")}`;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 500) {
      GlobalStore.setError("Internal Server Error");
      return Promise.reject();
    }

    if (error?.message === "Network Error" && !error?.response) {
      if (error?.config?.url?.includes("coingecko.com")) {
        console.log("Coingecko CORS");
        return Promise.reject(error);
      }
      if (error?.config?.url?.includes("ws.dev.fntworld.io")) {
        console.log("socket error");
        return Promise.reject(error);
      }
      console.log("Network Error:", error);
      GlobalStore.setError("Network Error");
      if (error?.response?.status !== 401) {
        return Promise.reject(error);
      }
    }

    if (
      error?.response?.status === 403 &&
      error?.response?.data?.message === "User is blocked"
    ) {
      UserStore.logOut();
      return Promise.reject(error);
    }

    if (error?.response?.status !== 401) {
      console.log(error);
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      if (error?.config?.url?.includes("api.itez.com")) {
        console.log("Itez Error");
        return Promise.reject(error);
      }
      if (error?.config?.url?.includes("paybis.com")) {
        console.log("Paybis Error");
        return Promise.reject(error);
      }

      if (error?.config?.url?.includes("firebasedatabase")) {
        console.log("Copy Trade Error");
        return Promise.reject(error);
      }
      if (localStorage.getItem("companyAccess")) {
        checkIsLogged();
        return Promise.reject(error);
      }
      UserStore.logOut();
      return Promise.reject(error);
    }
  }
);
