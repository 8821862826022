import { FC, useRef, useEffect, useCallback, useState } from "react";
import getChartConfig from "./chartConfig";
import { createChart, IChartApi, Time } from "lightweight-charts";
import { ChartDiv } from "./styled";
import type { ChartType } from "helpers/types";
// import { OHLCInterface } from "./";

export interface OHLCInterface {
  time: Time;
  open: number;
  high: number;
  low: number;
  close: number;
}
// export type OHLCType = CandlestickData<Time> | WhitespaceData<Time>;

interface TradesChartPerops {
  OHLC: OHLCInterface[];
  chartType: ChartType;
}

const TradesChart: FC<TradesChartPerops> = ({ OHLC, chartType }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [chart, setChart] = useState<undefined | IChartApi>(undefined);

  const createCandleChart = useCallback(() => {
    if (ref.current) {
      const config = getChartConfig({ clientWidth: ref.current.clientWidth });

      const newChart = createChart(ref.current, config);

      newChart.timeScale().fitContent();

      const lineSeries = newChart.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
      });

      lineSeries.setData(OHLC);
      setChart(newChart);
    }
  }, [OHLC]);

  const createLineChart = useCallback(() => {
    if (ref.current) {
      const config = getChartConfig({ clientWidth: ref.current.clientWidth });

      const newChart = createChart(ref.current, config);
      newChart.timeScale().fitContent();
      const lineSeries = newChart.addAreaSeries({
        topColor: "rgba( 38, 166, 154, 0.25)",
        bottomColor: "rgba( 38, 166, 154, 0)",
        lineColor: "rgba( 38, 166, 154, 1)",
        lineWidth: 2,
      });

      lineSeries.setData(
        OHLC.map(({ time, close }) => ({
          time: time,
          value: close,
        }))
      );
      setChart(newChart);
    }
  }, [OHLC]);

  useEffect(() => {
    if (chart) {
      chart.remove();
    }
    if (chartType === "LINE") {
      createLineChart();
    }
    if (chartType === "CANDLE") {
      createCandleChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartType, OHLC]);

  return <ChartDiv ref={ref} />;
};

export default TradesChart;
