import { useState, FC } from "react";
import MainButton, { MainButtonProps } from "./MainButton";
import { Preloader } from "components";

interface RequestButtonProps extends MainButtonProps {
  asyncFunc: (...args: string[]) => Promise<any>;
}

const RequestButton: FC<RequestButtonProps> = ({
  asyncFunc,
  children,
  ...otherProps
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const triggerReq = () => {
    setIsLoading(true);
    asyncFunc().finally(() => setIsLoading(false));
  };

  return (
    <MainButton
      onClick={() => triggerReq()}
      disabled={isLoading}
      {...otherProps}
    >
      {isLoading && <Preloader size={26} isStatic />}
      {children}
    </MainButton>
  );
};

export default RequestButton;
