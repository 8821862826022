import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import TradeInfoRows from "./TradeInfoRows";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import type { OperationType, MarketsType } from ".";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
} from "../styled";
import type {
  AvailableTradeOptions,
  MarketsInterface,
  CurrencyInterface,
} from "helpers/types";

import boughtImg from "assets/images/bought_success.jpg";
import sellImg from "assets/images/sell_success.jpg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface SuccessTradeStepProps {
  onClose: () => void;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  leftCurrency: AvailableTradeOptions;
  rightCurrency: AvailableTradeOptions;
  operationType: OperationType;
  cost: number;
  currentExchangeMarket: MarketsInterface | null;
  marketType: MarketsType;
  currencies: CurrencyInterface[] | null;
  currentFee: number;
}

const SuccessTradeStep: FC<SuccessTradeStepProps> = ({
  onClose,
  leftCurrencyValue,
  rightCurrencyValue,
  leftCurrency,
  rightCurrency,
  operationType,
  currentFee,
  cost,
  currentExchangeMarket,
  marketType,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img
            src={operationType === "buy" ? boughtImg : sellImg}
            alt="decorative"
          />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_600}
              lineHeight={30}
            >
              {currentExchangeMarket!.name}
            </AppText>
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {marketType === "limit"
                ? t("LIMIT_ORDER_CONFIRMED")
                : operationType === "buy"
                ? t("MARKET_PURCHASE_CONFIRMED")
                : t("MARKET_SALE_CONFIRMED")}
            </AppText>
            <StatusInfoLine>
              {currencyIcons[
                operationType === "sell" ? leftCurrency : rightCurrency
              ] || <NAIcon />}
              <AppText
                fontSize={20}
                fontWeight={600}
                color={colors.gray_700}
                lineHeight={30}
              >
                {operationType === "sell"
                  ? normolizeDecimalsWithNoCode(
                      Number(leftCurrencyValue),
                      leftCurrency,
                      currencies
                    )
                  : normolizeDecimalsWithNoCode(
                      Number(rightCurrencyValue),
                      rightCurrency,
                      currencies
                    )}
              </AppText>
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {` ${operationType === "sell" ? leftCurrency : rightCurrency}`}
              </AppText>
            </StatusInfoLine>
          </InfoColumn>

          <TradeInfoRows
            leftCurrencyValue={leftCurrencyValue}
            rightCurrencyValue={rightCurrencyValue}
            leftCurrency={leftCurrency}
            rightCurrency={rightCurrency}
            currentFee={currentFee}
            cost={cost}
            currentExchangeMarket={currentExchangeMarket}
            currencies={currencies}
            marketType={marketType}
          />

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessTradeStep;
