import { FC } from "react";
import { AppText } from "components";
import styled from "styled-components";
import { colors, currencyIcons } from "helpers/consts";

import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface PairExchangeCellProps {
  from: string;
  to: string;
  fromValue: string;
  toValue: string;
  rate?: string;
  minWidth?: string;
}

const PairExchangeCell: FC<PairExchangeCellProps> = ({
  from,
  to,
  fromValue,
  toValue,
  minWidth,
  rate,
}) => {
  return (
    <RowInfoCell $minWidth={minWidth}>
      <RowPairCell>
        {currencyIcons[from] || <NAIcon />}
        <AppText isSpan color={colors.gray_900}>
          {fromValue.includes("-") ? fromValue : `-${fromValue}`}
        </AppText>
      </RowPairCell>
      {rate && (
        <RowPairCell
          style={{
            paddingTop: "0.2rem",
            marginLeft: "2.6rem",
          }}
        >
          <AppText color={colors.gray_500} fontSize={11} fontWeight={500}>
            x{rate}
          </AppText>
        </RowPairCell>
      )}
      <RowPairCell>
        {currencyIcons[to] || <NAIcon />}
        <AppText isSpan color={colors.gray_900}>
          {toValue}
        </AppText>
      </RowPairCell>
    </RowInfoCell>
  );
};

const RowPairCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > svg {
    max-height: 2rem;
    max-width: 2rem;
  }

  & > *:not(:first-child) {
    margin-left: 0.6rem;
  }
`;

interface RowInfoCellProps {
  $minWidth?: string;
}
const RowInfoCell = styled.div<RowInfoCellProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}

  & > *:not(:first-child) {
    margin-top: 0.4rem;
  }
`;

export default PairExchangeCell;
