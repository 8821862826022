import { FC } from "react";
import { observer } from "mobx-react-lite";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { ModalHeading, ModalContent, StatusDecorative } from "./styled";
import { CardImage, CardInfoRows } from "components";
import type { ExistPhazeProductInterface } from "helpers/types";

interface CardInfoModalProps {
  card?: ExistPhazeProductInterface | null;
  isOpen: boolean;
  onClose: () => void;
}
const CardInfoModal: FC<CardInfoModalProps> = ({ card, onClose, isOpen }) => {
  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading $centered>{card && card.productName}</ModalHeading>
        <StatusDecorative $noIcon>
          <CardImage
            src={card ? card.productImg : undefined}
            alt={card ? card.productName : ""}
          />
        </StatusDecorative>

        <CardInfoRows info={card?.vouchers[0]} inModal />
      </ModalContent>
    </BasicModal>
  );
};

export default observer(CardInfoModal);
