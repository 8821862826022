import axios from "axios";
import { CardsStore, GlobalStore } from "stores";
import type {
  PhazeProductInterface,
  ExistPhazeProductInterface,
} from "helpers/types";
import type {
  GetCountryProductsInterface,
  GetCountryProductsResponse,
  PurchaseData,
  GetProductsReqData,
  GetProductsResponse,
  UpdateUsersProductInterface,
} from "./apiTypes";

export const getPhazeCountries = async () =>
  axios.get<string[]>("/phaze/country/list");

export const getCountryProducts = async (data: GetCountryProductsInterface) =>
  axios.get<GetCountryProductsResponse>(
    `/phaze/product/country/${data.country}`,
    {
      params: { ...data },
    }
  );

export const getCertainProduct = async (productId: number) =>
  axios.get<PhazeProductInterface>(`/phaze/product/${productId}`, {
    params: { productId },
  });

export const purchasePhazeCard = async (purchaseData: PurchaseData) =>
  axios.post<ExistPhazeProductInterface>(`/phaze/purchase`, purchaseData);

export const getPhazeProducts = async (data: GetProductsReqData) =>
  axios.get<GetProductsResponse>(`/phaze/product/list`, {
    params: { ...data },
  });

export const getUsersPhazeProducts = async () => {
  const reqData: GetProductsReqData = {
    page: 0,
    itemsPerPage: 10000,
  };
  return axios
    .get<GetProductsResponse>(`/phaze/product/list`, {
      params: reqData,
    })
    .then(({ data }) => {
      CardsStore.setGiftCardsList(data.items);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });
};

export const updateUsersProduct = async (data: UpdateUsersProductInterface) =>
  axios.put<ExistPhazeProductInterface>(
    `/phaze/product/${data.productId}`,
    data.newData
  );
