import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import { AppText } from "components";
import UnstakeInfoRows from "./UnstakeInfoRows";
import {
  toLocaleStringWithCurrency,
  normolizeDecimalsWithNoCode,
} from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContent,
  ConfirmBlock,
  ConfirmInfo,
} from "../styled";
import type {
  StakeInterface,
  CurrencyInterface,
  StakeStrategyInterface,
} from "helpers/types";

import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface MainTradeStepProps {
  onSubmit: () => void;
  onBack: () => void;
  onClose: () => void;
  currentUntake: StakeInterface | null;
  currencies: CurrencyInterface[] | null;
  stakeSrategies: StakeStrategyInterface[] | null;
  cost: number;
  isforwardUnstake?: boolean;
  isLoading: boolean;
}

const ConfirmStakeStep: FC<MainTradeStepProps> = ({
  onSubmit,
  onBack,
  onClose,
  currencies,
  currentUntake,
  isforwardUnstake,
  isLoading,
  stakeSrategies,
}) => {
  const { t } = useTranslation();

  const unstakeFees = useMemo<number>(() => {
    if (!currentUntake) {
      return 0;
    }
    const currentFees = stakeSrategies?.find(
      ({ id }) => id === currentUntake?.strategyId
    );
    if (currentFees && Number(currentFees.unstakingFixedFee) > 0) {
      return Number(currentFees.unstakingFixedFee);
    }
    if (currentFees && Number(currentFees.unstakingPercentFee) > 0) {
      return (
        Number(currentUntake.amount) *
        (Number(currentFees.unstakingPercentFee) / 100)
      );
    }

    return 0;
  }, [stakeSrategies, currentUntake]);

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        {!isforwardUnstake && <BackModalButton onBack={onBack} />}

        <ModalHeading $centered>{t("UNSTAKE_CONFIRMATION")}</ModalHeading>
        <InnerContent>
          <ConfirmBlock>
            {currencyIcons[currentUntake!.currencyName] ? (
              currencyIcons[currentUntake!.currencyName]
            ) : (
              <NAIcon />
            )}
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {normolizeDecimalsWithNoCode(
                Number(currentUntake!.amount),
                currentUntake!.currencyName,
                currencies
              )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {" "}
                {currentUntake!.currencyName}
              </AppText>
            </AppText>

            <ConfirmInfo>
              <AppText fontWeight={400} color={colors.gray_700}>
                {t("UNSTAKE_CONFIRM")} {currentUntake!.currencyName}?
              </AppText>
            </ConfirmInfo>
          </ConfirmBlock>

          {unstakeFees > 0 && (
            <UnstakeInfoRows
              fees={unstakeFees}
              currency={currentUntake!.currencyName}
            />
          )}

          <ModalButton onClick={onSubmit} disabled={isLoading}>
            {t("UNSTAKE")}{" "}
            {toLocaleStringWithCurrency(
              Number(currentUntake!.amount) - unstakeFees,
              currentUntake!.currencyName,
              currencies
            )}
          </ModalButton>
        </InnerContent>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmStakeStep;
