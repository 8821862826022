import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import {
  toLocaleStringWithCurrency,
  convertCurrencyValue,
} from "helpers/funcs";
import { InfoRows } from "../styled";
import type { PaymentVariantsProps, PricesInterface } from "helpers/types";

interface DepositInfoRowsProps {
  currency?: PaymentVariantsProps;
  prices: PricesInterface | null;
}

const DepositInfoRows: FC<DepositInfoRowsProps> = ({ currency, prices }) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      {currency?.minDeposit && Number(currency.minDeposit) > 0 && (
        <ModalRow
          keyText={t("MINIMUM_DEPOSIT")}
          value={toLocaleStringWithCurrency(
            currency.minDeposit,
            currency?.name || "USD"
          )}
          isBold
        />
      )}

      {currency?.maxDeposit && Number(currency.maxDeposit) > 0 && (
        <ModalRow
          keyText={t("MAXIMUM_DEPOSIT")}
          value={toLocaleStringWithCurrency(
            currency.maxDeposit,
            currency?.name || "USD"
          )}
          isBold
        />
      )}

      <ModalRow
        keyText={t("FEES")}
        value={toLocaleStringWithCurrency(
          currency?.depositFee || 0,
          currency?.name || "USD"
        )}
        subValue={
          prices && currency && currency.name !== "USD"
            ? toLocaleStringWithCurrency(
                convertCurrencyValue(
                  currency?.name || "USD",
                  "USD",
                  Number(currency?.depositFee) || 0,
                  prices
                ),
                "USD"
              )
            : ""
        }
        isBold
      />
    </InfoRows>
  );
};

export default DepositInfoRows;
