import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { CloseModalButton, BackModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { ModalHeading, ModalContent, InfoDecorative } from "./styled";
import { AppText, Preloader } from "components";
import { getCreditcoCardBalance } from "api/creditco";
import { GlobalStore } from "stores";

interface CardPinModalProps {
  cardId?: number;
  cardCurrency?: string | null;
  isOpen: boolean;
  onClose: () => void;
}
const CardBalanceModal: FC<CardPinModalProps> = ({
  cardId,
  cardCurrency,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();

  const [cardBalance, setCardBalance] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (cardId && isOpen) {
      setIsLoading(true);
      getCreditcoCardBalance(cardId)
        .then(({ data }) => setCardBalance(data.balance))
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [cardId, isOpen]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={() => setCardBalance(0)}
      onClose={onClose}
    >
      <ModalContent>
        {isLoading && <Preloader isStatic />}
        <BackModalButton onBack={onClose} label="back" />
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading $centered>{t("CARD_BALANCE")}</ModalHeading>

        <InfoDecorative>
          <AppText fontSize={26} fontWeight={600}>
            {cardBalance} {cardCurrency || "EUR"}
          </AppText>
        </InfoDecorative>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(CardBalanceModal);
