import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import {
  toLocaleStringWithCurrency,
  normolizeCurrenciesDecimals,
} from "helpers/funcs";
import { InfoRows } from "components/Modals/styled";
import type { AvailableTradeOptions, CurrencyInterface } from "helpers/types";

interface TradeInfoRowsProps {
  currencyValue: string;
  plasticCost: string;
  deliveryCost: string;
  currency: AvailableTradeOptions | null;
  productCurrency?: string | null;
  cost: number;
  servicesCost: number;
  currencies: CurrencyInterface[] | null;
}

const BuyInfoRows: FC<TradeInfoRowsProps> = ({
  currencyValue,
  plasticCost,
  deliveryCost,
  currency,
  productCurrency,
  cost,
  servicesCost,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("RATE")}
        value={`${cost > 0 ? "≈ " : ""}${toLocaleStringWithCurrency(
          cost,
          productCurrency || "USD"
        )}`}
      />
      <ModalRow
        keyText={t("CARD_COST")}
        value={`${normolizeCurrenciesDecimals(
          (Number(currencyValue) || 0) / Number(cost),
          currency || "USD",
          currencies,
          "forView"
        )} ${currency || ""}`}
      />
      <ModalRow
        keyText={t("PLASTIC_COST")}
        value={`${normolizeCurrenciesDecimals(
          (Number(plasticCost) || 0) / Number(servicesCost),
          currency || "USD",
          currencies,
          "forView"
        )} ${currency || ""}`}
      />
      <ModalRow
        keyText={t("DELIVERY_COST")}
        value={`${normolizeCurrenciesDecimals(
          (Number(deliveryCost) || 0) / Number(servicesCost),
          currency || "USD",
          currencies,
          "forView"
        )} ${currency || ""}`}
      />
    </InfoRows>
  );
};

export default BuyInfoRows;
