import { FC, ReactNode, MouseEvent } from "react";
import styled from "styled-components";
import { SquereButton } from "components/Buttons";
import { Tooltip } from "components";
import type { ModalTypes, AvailablePayments } from "helpers/types";

interface ControlButtonsInterface {
  label: ModalTypes;
  icon: ReactNode;
}

interface ControlsCellProps {
  buttons: ControlButtonsInterface[];
  onClick: (
    arg: ModalTypes,
    initialCoin: AvailablePayments,
    isFiat?: boolean
  ) => void;
  initialCoin: AvailablePayments;
  toActivateFiat?: boolean;
  disabled?: boolean;
  isStakeDisabled?: boolean;
  isDepositDisabled?: boolean;
  isWithdrawDisabled?: boolean;
  isTradeDisabled?: boolean;
}

const ControlsCell: FC<ControlsCellProps> = ({
  buttons,
  onClick,
  initialCoin,
  toActivateFiat = false,
  disabled = false,
  isStakeDisabled = false,
  isDepositDisabled = false,
  isWithdrawDisabled = false,
  isTradeDisabled = false,
}) => {
  const fn = (e: MouseEvent<HTMLElement>, label: ModalTypes) => {
    e.stopPropagation();
    onClick(label, initialCoin, toActivateFiat);
  };

  return (
    <ControlsCellContainer>
      {buttons.map(({ label, icon }) =>
        disabled ||
        (label === "stake" && isStakeDisabled) ||
        (label === "deposit" && isDepositDisabled) ||
        (label === "withdraw" && isWithdrawDisabled) ||
        (label === "trade" && isTradeDisabled) ? (
          <SquereButton
            onClick={(e) => fn(e, label)}
            key={label}
            $view="transparent"
            aria-label={label}
            disabled={
              disabled ||
              isStakeDisabled ||
              isDepositDisabled ||
              isWithdrawDisabled ||
              isTradeDisabled
            }
          >
            {icon}
          </SquereButton>
        ) : (
          <Tooltip title={label} key={label}>
            <SquereButton
              onClick={(e) => fn(e, label)}
              $view="transparent"
              aria-label={label}
            >
              {icon}
            </SquereButton>
          </Tooltip>
        )
      )}
    </ControlsCellContainer>
  );
};

const ControlsCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }

  @media (min-width: 48em) {
    & > *:not(:first-child) {
      margin-left: 0.4rem;
    }
    justify-content: flex-end;
  }
`;

export default ControlsCell;
