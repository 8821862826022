import styled from "styled-components";
import { colors } from "helpers/consts";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_200};
  border-radius: 1.2rem;
`;

export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;

  & > h2 {
    margin-bottom: 0.8rem;
  }
`;

export const LineContent = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    max-height: 2.6rem;
    margin: 1.2rem 0 0 0rem;
  }

  @media (min-width: 28em) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    & > div {
      max-height: 2.6rem;
      margin: 0 0 0 1.2rem;
    }
  }
`;

export const TopContent = styled.div`
  width: 100%;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray_200};

  & > svg {
    min-width: 6rem;
    min-height: 6rem;
    max-height: 6rem;
    max-width: 6rem;
  }

  @media (min-width: 36em) {
    padding: 2.4rem;
  }
`;

export const ChartContent = styled.div`
  width: 100%;
  padding: 1.6rem 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 36em) {
    padding: 2.4rem 2.4rem 4.8rem;
  }
`;

export const ChartDiv = styled.div`
  width: 100%;
`;
