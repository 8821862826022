import type { LangType } from "helpers/types";
import { ColorType, PriceScaleMode } from "lightweight-charts";

interface ChartConfigInterface {
  clientWidth: number;
  locale?: LangType;
}
const getChartConfig = ({
  clientWidth,
  locale = "en",
}: ChartConfigInterface) => {
  return {
    height: 212,
    width: clientWidth,
    layout: {
      background: { type: ColorType.Solid, color: "white" },
      textColor: "black",
    },
    localization: {
      locale,
    },
    // new
    rightPriceScale: {
      scaleMargins: {
        top: 0.3,
        bottom: 0.25,
      },
      borderVisible: false,
      mode: PriceScaleMode.IndexedTo100,
    },
    PriceScaleMode: {},
    timeScale: {
      borderVisible: false,
    },
    grid: {
      vertLines: {
        visible: false,
      },
      // horzLines: {
      //   visible: false,
      // },
    },
  };
};

export default getChartConfig;
