import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MainButton, { MainButtonProps } from "./MainButton";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-narrow-left.svg";

const BackButton: FC<MainButtonProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledMainButton {...props} variant="text">
      <ArrowIcon />
      {t("BACK")}
    </StyledMainButton>
  );
};

interface StyledMainButtonInterface {
  $maxWidth?: string;
}
const StyledMainButton = styled(MainButton)<StyledMainButtonInterface>`
  margin-bottom: 0.6rem;
  max-width: fit-content;

  @media (min-width: 48em) {
    margin-bottom: 2.2rem;
  }
`;

export default BackButton;
