import { FC, ReactNode, CSSProperties } from "react";
import { colors } from "helpers/consts";
import styled from "styled-components";

import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";

interface ChipProps {
  children: ReactNode;
  isSuccess: boolean;
  isError?: boolean;
  withBorder?: boolean;
  isNeutral?: boolean;
  isLarge?: boolean;
  withArrow?: boolean;
  isWieght?: boolean;
  style?: CSSProperties;
}
const Chip: FC<ChipProps> = ({
  children,
  isSuccess,
  withBorder,
  isNeutral,
  isError,
  isLarge,
  withArrow,
  isWieght,
  style,
  ...props
}) => (
  <StyledChip
    $isSuccess={isSuccess}
    $withBorder={withBorder}
    $isNeutral={isNeutral}
    $isLarge={isLarge}
    $isWieght={isWieght}
    $isError={isError}
    style={style}
    {...props}
  >
    {withArrow && <ArrowUpIcon />}
    {children}
  </StyledChip>
);

interface StyledChipProps {
  $isSuccess: boolean;
  $withBorder?: boolean;
  $isNeutral?: boolean;
  $isLarge?: boolean;
  $isWieght?: boolean;
  $isError?: boolean;
}

const StyledChip = styled.div<StyledChipProps>`
  display: flex;
  padding: 0.2rem 0.8rem;
  align-items: center;
  border-radius: 1.6rem;
  ${({ $isNeutral, $isSuccess, $isError }) => {
    if ($isError) {
      return `background-color: ${colors.error_50}; color: ${colors.error_700};`;
    }
    if ($isNeutral) {
      return `background-color: ${colors.primary_50}; color: ${colors.error_700};`;
    }
    return `background-color: ${
      $isSuccess ? colors.success_50 : colors.error_50
    }; 
    color: ${$isSuccess ? colors.success_700 : colors.error_700};`;
  }}
  ${({ $isNeutral, $isSuccess, $withBorder, $isError }) => {
    if ($withBorder) {
      if ($isError) {
        return `border: solid 1px ${colors.error_200};`;
      }
      if ($isNeutral) {
        return `border: solid 1px ${colors.primary_200};`;
      }
      return `border: solid 1px ${
        $isSuccess ? colors.success_200 : colors.error_200
      };`;
    }
  }}
  mix-blend-mode: multiply;
  pointer-events: none;
  font-size: ${({ $isLarge }) => ($isLarge ? "1.4rem;" : "1.2rem;")}
  ${({ $isWieght }) => $isWieght && "font-weight: 600;"}
  text-transform: capitalize;
  white-space: nowrap;
  line-height: 1.5;
  width: fit-content;

  > svg {
    max-width: 1.2rem;
    max-height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem;
    margin-right: 0.4rem;
    ${({ $isSuccess }) => !$isSuccess && "transform: rotate(180deg);"}

    path {
      stroke: ${({ $isSuccess }) =>
        $isSuccess ? colors.success_400 : colors.error_400};
    }
  }
`;

export default Chip;
