import { makeAutoObservable } from "mobx";
import { FIAT_OPTIONS } from "helpers/fiatOptions";
import { rightCurrencyOptions } from "pages/CryptoTransfersPage/innerPages/BuyCrypto";
import type {
  ItezData,
  PaybisBuyCryptoPairsData,
  PaybisSellCryptoPairsData,
  AvailableTradeOptions,
} from "helpers/types";

class Store {
  widgetLoadError: boolean = false;
  itezData: ItezData | null = null;
  paybisWidgetId: string | null = null;

  buyCryptoPaySystem: string = "Paybis";
  buyCryptoPayMethodVariant: string = "";
  buyCryptoPaybisPairsData: PaybisBuyCryptoPairsData[] | null = null;
  buyCryptoLeftCurrency: AvailableTradeOptions = FIAT_OPTIONS[0].value;
  buyCryptoRightCurrency: AvailableTradeOptions = rightCurrencyOptions[0];

  sellCryptoPaySystem: string = "Paybis";
  sellCryptoPayMethodVariant: string = "";
  sellCryptoPaybisPairsData: PaybisSellCryptoPairsData[] | null = null;
  sellCryptoLeftCurrency: AvailableTradeOptions = "USDT";
  sellCryptoRightCurrency: AvailableTradeOptions = "EUR";

  constructor() {
    makeAutoObservable(this);
  }

  setItezData = (data: ItezData) => {
    this.itezData = data;
  };

  clearItezData = () => {
    this.itezData = null;
  };

  setPaybisData = (data: string) => {
    this.paybisWidgetId = data;
  };

  clearPaybisData = () => {
    this.paybisWidgetId = null;
  };

  setWidgetError = () => {
    this.widgetLoadError = true;
  };

  setBuyCryptoPaySystem = (paySystem: string) => {
    this.buyCryptoPaySystem = paySystem;
  };
  setBuyCryptoPayMethodVariant = (payVariant: string) => {
    this.buyCryptoPayMethodVariant = payVariant;
  };
  setBuyCryptoPaybisPairsData = (data: PaybisBuyCryptoPairsData[] | null) => {
    this.buyCryptoPaybisPairsData = data;
  };
  setBuyCryptoLeftCurrency = (curr: AvailableTradeOptions) => {
    this.buyCryptoLeftCurrency = curr;
  };
  setBuyCryptoRightCurrency = (curr: AvailableTradeOptions) => {
    this.buyCryptoRightCurrency = curr;
  };

  setSellCryptoPaySystem = (paySystem: string) => {
    this.sellCryptoPaySystem = paySystem;
  };
  setSellCryptoPayMethodVariant = (payVariant: string) => {
    this.sellCryptoPayMethodVariant = payVariant;
  };
  setSellCryptoPaybisPairsData = (data: PaybisSellCryptoPairsData[] | null) => {
    this.sellCryptoPaybisPairsData = data;
  };
  setSellCryptoLeftCurrency = (curr: AvailableTradeOptions) => {
    this.sellCryptoLeftCurrency = curr;
  };
  setSellCryptoRightCurrency = (curr: AvailableTradeOptions) => {
    this.sellCryptoRightCurrency = curr;
  };
}

const thirdPartyStore = new Store();
export default thirdPartyStore;
