import {
  FC,
  useState,
  MouseEvent,
  // useCallback, useMemo
} from "react";
import { useNavigate } from "react-router-dom";
// import { useSDK } from "@metamask/sdk-react";
import { Badge } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SquereButton } from "components/Buttons";
import { AppText } from "components";
import { UserStore } from "stores";
import routesPaths from "routes/routesPaths";
import { colors, supportUrl } from "helpers/consts";
import { token } from "api/user";
// import { convertWeiToEth } from "helpers/funcs";
import {
  UserMenuContainer,
  StyledMenu,
  StyledMenuItem,
  UserInfo,
  MenuDivider,
  Image,
} from "../styled";

import foxImage from "assets/images/metamask.png";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as DocumentsIcon } from "assets/icons/documents.svg";
import { ReactComponent as ZapIcon } from "assets/icons/zap.svg";
import { ReactComponent as LayersIcon } from "assets/icons/layers-two.svg";
import { ReactComponent as UserPlusIcon } from "assets/icons/user-plus.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/log-out.svg";
import { ReactComponent as MetaMaskIcon } from "assets/icons/metamask.svg";
import { ReactComponent as SignInIcon } from "assets/icons/signIn.svg";
import { ReactComponent as SignUpIcon } from "assets/icons/signUp.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
// import { ReactComponent as CheckIcon } from "assets/icons/check-min-circle.svg";
// import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";

interface UserMenuProps {
  isInSidebar?: boolean;
}
const UserMenu: FC<UserMenuProps> = ({ isInSidebar }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    user: { email },
    isAuthenticated,
    requestedForms,
    isLoggedFromTelegram,
  } = UserStore;
  // const { sdk, connected, account, balance } = useSDK();

  const [isProfileMenuOpen, setIsProfileMenuOpen] =
    useState<null | HTMLElement>(null);
  const [isMetaMaskOpen, setIsMetaMaskOpen] = useState<null | HTMLElement>(
    null
  );
  const handleOpenProfileMenu = (e: MouseEvent<HTMLElement>) => {
    setIsProfileMenuOpen(e.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setIsProfileMenuOpen(null);
  };
  const handleOpenMetaMusk = (e: MouseEvent<HTMLElement>) => {
    setIsMetaMaskOpen(e.currentTarget);
  };
  const handleCloseMetaMusk = () => {
    setIsMetaMaskOpen(null);
  };
  const navigateToProfile = (url: string) => {
    navigate(url);
    handleCloseProfileMenu();
  };
  const navigateToAuth = (path: string) => {
    navigate(path);
    handleCloseProfileMenu();
  };
  const fullLogOut = () => {
    token.unset();
    window.location.reload();
  };

  const sendEmail = () => {
    window.location.assign(`mailto:${supportUrl}`);
  };

  // const connect = useCallback(async () => {
  //   try {
  //     await sdk?.connect();
  //   } catch (err) {
  //     console.warn("failed to connect..", err);
  //   }
  // }, [sdk]);

  // const disconnect = useCallback(async () => {
  //   try {
  //     await sdk?.terminate();
  //   } catch (err) {
  //     console.warn("failed to connect..", err);
  //   }
  // }, [sdk]);

  // const connectedAccount = useMemo<string | undefined>(
  //   () =>
  //     account
  //       ? `${account.substring(0, 7)}...${account.substring(
  //           account.length - 5
  //         )}`
  //       : undefined,
  //   [account]
  // );

  return (
    <UserMenuContainer $isInSidebar={isInSidebar}>
      <SquereButton
        onClick={handleOpenProfileMenu}
        aria-controls={isProfileMenuOpen ? t("PROFILE_MENU") : undefined}
        aria-haspopup="true"
        aria-expanded={isProfileMenuOpen ? "true" : undefined}
      >
        <Badge
          badgeContent={requestedForms?.length || 0}
          invisible={!requestedForms || requestedForms.length === 0}
        >
          <UserIcon />
        </Badge>
      </SquereButton>

      <SquereButton
        onClick={handleOpenMetaMusk}
        aria-controls={isMetaMaskOpen ? t("METAMASK_MENU") : undefined}
        aria-haspopup="true"
        aria-expanded={isMetaMaskOpen ? "true" : undefined}
      >
        <Image src={foxImage} />
      </SquereButton>

      {isAuthenticated ? (
        <StyledMenu
          anchorEl={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          onClose={handleCloseProfileMenu}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <UserInfo tabIndex={-1}>
            <AppText noWrap color={colors.gray_700} fontWeight={600}>
              {email}
            </AppText>
          </UserInfo>
          <MenuDivider />
          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.profile)}
          >
            <UserIcon />
            <AppText>{t("PROFILE_SETTINGS")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.documents)}
          >
            <DocumentsIcon />
            <AppText>{t("DOCUMENTS")}</AppText>
            {requestedForms && requestedForms.length > 0 ? (
              <Badge badgeContent={requestedForms.length} />
            ) : null}
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.verification)}
          >
            <ZapIcon />
            <AppText>{t("VERIFICATION")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.activityLog)}
          >
            <LayersIcon />
            <AppText>{t("ACTIVITY_LOG")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.referrals)}
          >
            <UserPlusIcon />
            <AppText>{t("REFERRALS")}</AppText>
          </StyledMenuItem>
          <MenuDivider />
          <StyledMenuItem onClick={sendEmail}>
            <MailIcon />
            <AppText>{t("CONTACT_SUPPORT")}</AppText>
          </StyledMenuItem>

          {!isLoggedFromTelegram && (
            <StyledMenuItem onClick={fullLogOut}>
              <LogOutIcon />
              <AppText>{t("LOG_OUT")}</AppText>
            </StyledMenuItem>
          )}
        </StyledMenu>
      ) : (
        <StyledMenu
          anchorEl={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          onClose={handleCloseProfileMenu}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <StyledMenuItem onClick={() => navigateToAuth(routesPaths.login)}>
            <SignInIcon />
            <AppText>{t("LOGIN")}</AppText>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => navigateToAuth(routesPaths.signUp)}>
            <SignUpIcon />
            <AppText>{t("SIGN_UP")}</AppText>
          </StyledMenuItem>
        </StyledMenu>
      )}

      <StyledMenu
        anchorEl={isMetaMaskOpen}
        open={!!isMetaMaskOpen}
        onClose={handleCloseMetaMusk}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={() => null}>
          <MetaMaskIcon />
          <AppText>{t("CONNECT_METAMASK")}</AppText>
        </StyledMenuItem>
        {/* {connected ? (
          <div>
            <UserInfo tabIndex={-1}>
              <CheckIcon />
              <AppText
                noWrap
                color={colors.gray_700}
                style={{ marginRight: "0.4rem" }}
                fontWeight={500}
              >
                {connectedAccount ? "" : "MetaMask "}
                {t("CONNECTED")}
                {connectedAccount ? ":" : ""}
              </AppText>
              <AppText noWrap color={colors.gray_700} fontWeight={600}>
                {connectedAccount}
              </AppText>
            </UserInfo>

            {connectedAccount ? (
              <>
                {balance && (
                  <UserInfo tabIndex={-1}>
                    <WalletIcon />
                    <AppText
                      noWrap
                      color={colors.gray_700}
                      style={{ marginRight: "0.4rem" }}
                      fontWeight={500}
                    >
                      {t("BALANCE")}:
                    </AppText>
                    <AppText noWrap color={colors.gray_700} fontWeight={600}>
                      {convertWeiToEth(balance)} ETH
                    </AppText>
                  </UserInfo>
                )}

                <StyledMenuItem onClick={disconnect}>
                  <MetaMaskIcon />
                  <AppText>{t("DISCONNECT_METAMASK_ACCOUNT")}</AppText>
                </StyledMenuItem>
              </>
            ) : (
              <StyledMenuItem onClick={connect}>
                <MetaMaskIcon />
                <AppText>{t("CONNECT_ACCOUNT")}</AppText>
              </StyledMenuItem>
            )}
          </div>
        ) : (
          <StyledMenuItem onClick={connect}>
            <MetaMaskIcon />
            <AppText>{t("CONNECT_METAMASK")}</AppText>
          </StyledMenuItem>
        )} */}
      </StyledMenu>
    </UserMenuContainer>
  );
};

export default observer(UserMenu);
