import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  InfoColumn,
} from "../styled";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface SuccessPurchasePlasticStepProps {
  onClose: () => void;
}

const SuccessPurchasePlasticStep: FC<SuccessPurchasePlasticStepProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative $isTransparent>
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>

        <InfoColumn>
          <AppText
            style={{ marginTop: "1.4rem" }}
            fontSize={18}
            fontWeight={600}
            color={colors.gray_700}
          >
            {t("PLASTIC_CARD_SUCCESS_PURCHASE")}
          </AppText>

          <ModalButton style={{ marginTop: "1.4rem" }} onClick={onClose}>
            {t("CONTINUE")}
          </ModalButton>
        </InfoColumn>
      </StatusContent>
    </Fade>
  );
};

export default SuccessPurchasePlasticStep;
