import axios from "axios";
import type {
  ReqItezData,
  ResItezData,
  ItezPriceInfoData,
  ResItezPriceInfoData,
} from "./apiTypes";

export const callItez = async (buyCryptoData?: ReqItezData) =>
  axios.post<ResItezData>(`/itez/sign`, {
    json: buyCryptoData ? JSON.stringify(buyCryptoData) : "{}",
  });

export const getItezPrices = async (itezData: ItezPriceInfoData) =>
  axios.post<ResItezPriceInfoData>(
    `https://api.itez.com/api/partner/exchange/calculate`,
    {
      partner_token: itezData.key,
      signature: itezData.signature,
      timestamp: itezData.timestamp,
      from_currency: itezData.from_currency,
      to_currency: itezData.to_currency,
      to_amount: itezData.to_amount,
      // from_amount: itezData.from_amount,
    }
  );
