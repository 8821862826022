import { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "helpers/consts";
import { MainButton } from "components/Buttons";
import { AppText } from "components";

const Wrapper = styled.div`
  position: fixed;
  z-index: 12;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 1.6rem;
  background-color: ${colors.primary_25};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 30%);

  & > button {
    margin-left: 1rem;
  }

  & > p.MuiTypography-root {
    font-size: 1.2rem;
  }

  @media (min-width: 34em) {
    & > p.MuiTypography-root {
      font-size: 1.4rem;
    }
  }
`;

interface CookiesPopupProps {
  isAuthenticated: boolean;
}

const CookiesPopup: FC<CookiesPopupProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const [isCookieOpen, setIsCookieOpen] = useState(false);

  const accept = useCallback(() => {
    localStorage.setItem("cookiesAccept", "true");
    setIsCookieOpen(false);
  }, []);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookiesAccept");

    if (isAuthenticated && !isCookieAccepted) {
      setIsCookieOpen(true);
    }

    if (isCookieOpen && !isAuthenticated) {
      setIsCookieOpen(false);
    }
  }, [isAuthenticated, isCookieOpen]);

  return (
    <>
      {isCookieOpen && (
        <Wrapper>
          <AppText fontWeight={600}>{t("COOKIES_DESC")}</AppText>

          <MainButton minWidth="12rem" variant="contained" onClick={accept}>
            {t("ACCEPT")}
          </MainButton>
        </Wrapper>
      )}
    </>
  );
};

export default CookiesPopup;
