import { FC, useState, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import ConfirmMillionStakeStep from "./ConfirmCopyTradingStep";
import SuccessPurchaseStep from "./SuccessCopyTradingStep";
import type { CurrencyInterface, ModalStepsType } from "helpers/types";

interface CopyTradingModalProps {
  isOpen: boolean;
  onClose: () => void;
  currencyValue: string;
  currencies: CurrencyInterface[] | null;
}

const CopyTradingModal: FC<CopyTradingModalProps> = ({
  isOpen,
  onClose,
  currencyValue,
  currencies,
}) => {
  const [isLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ModalStepsType>("confirm");

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
     e.preventDefault();
    if (!currencyValue) {
      return;
    }

    setStep("success");
  };

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} title="Purchase card">
      {isLoading && <Preloader isStatic />}

      {step === "confirm" && (
        <ConfirmMillionStakeStep
          currencies={currencies}
          currencyValue={currencyValue}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}

      {step === "success" && (
        <SuccessPurchaseStep
          currencies={currencies}
          currencyValue={currencyValue}
          onClose={onClose}
        />
      )}
    </BasicModal>
  );
};

export default observer(CopyTradingModal);
