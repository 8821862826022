import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useSDK } from "@metamask/sdk-react";
import { MainButton, ModalButton } from "components/Buttons";
import {
  TransactionGroup,
  InfoLine,
  ModalError,
  ModalErrorStatus,
  ModalErrorInfo,
  InfoRows,
} from "../styled";
import { AppText } from "components";
import { ModalRow } from "components/Rows";
import { ReadInfo, CurrencyInput, RangeInput } from "components/Inputs";
import { colors } from "helpers/consts";
import { useDebounce } from "helpers/hooks";
import MetaMaskInfoRows from "./MetaMaskInfoRows";
import {
  convertWeiToEth,
  convertEthToWei,
  toLocaleStringWithCurrency,
} from "helpers/funcs";
import type { CurrencyInterface, ModalStepsType } from "helpers/types";

import { ReactComponent as MetaMaskIcon } from "assets/icons/metamask.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";

const exceedAmount = "ERROR_NO_AMOUNT";
const lowerThanMinAmount = "ERROR_MIN_AMOUNT";
const higherThanMaxAmount = "ERROR_MAX_AMOUNT";
const metamaskChainError = "ERROR_METAMASK_CHAIN";

interface DepositMetaMaskTabProps {
  walletAddress?: string;
  currencies: CurrencyInterface[] | null;
  setStep: Dispatch<SetStateAction<ModalStepsType>>;
  isOpen: boolean;
  paymentType?: CurrencyInterface;
  chosenNetwork: string;
}

const DepositMetaMaskTab: FC<DepositMetaMaskTabProps> = ({
  walletAddress,
  currencies,
  setStep,
  isOpen,
  paymentType,
  chosenNetwork,
}) => {
  const { t } = useTranslation();
  const { account, balance, provider, sdk, chainId } = useSDK();

  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const [estimateFee, setEstimateFee] = useState<number>(0);
  const [gasPrice, setGasPrice] = useState<number>(0);

  const convertedToEthBalance = useMemo<number>(() => {
    if (balance) {
      return Number(convertWeiToEth(balance));
    }
    return 0;
  }, [balance]);

  const metaMaskFees = useMemo<number>(
    () => (gasPrice * estimateFee) / 1e9,
    [estimateFee, gasPrice]
  );

  const minDeposit = useMemo<number>(
    () =>
      chosenNetwork && paymentType?.minDeposit
        ? Number(paymentType.minDeposit)
        : 0,
    [paymentType, chosenNetwork]
  );

  const maxDeposit = useMemo<number>(
    () =>
      chosenNetwork && paymentType?.maxDeposit
        ? Number(paymentType.maxDeposit)
        : 0,
    [paymentType, chosenNetwork]
  );

  const checkIfError = (value: number, chainId?: string) => {
    if (hasError) {
      setHasError(null);
    }
    if (chainId && chainId !== "0x1") {
      setHasError(t(metamaskChainError));
      return;
    }
    if (!value) {
      return;
    }
    if (Number(value) > Number(convertedToEthBalance)) {
      setHasError(t(exceedAmount));
    }

    if (minDeposit > 0 && value && Number(value) < minDeposit) {
      setHasError(`${t(lowerThanMinAmount)} (${minDeposit} ETH)`);
    }

    if (maxDeposit > 0 && value && Number(value) > maxDeposit) {
      setHasError(`${t(higherThanMaxAmount)} (${maxDeposit} ETH)`);
    }
  };

  const handleChangeValue = (value: string) => {
    const correctInvalidValue = value[0] === "." ? `0${value}` : value;

    setValue(correctInvalidValue);
    setIsLoading(true);
    checkIfError(Number(value), chainId);
  };

  const sendMetamask = useCallback(() => {
    if (account && provider) {
      provider
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: account,
              to: walletAddress,
              value: convertEthToWei(String(Number(value) - metaMaskFees)),
              gasPrice: convertEthToWei(String(gasPrice), "gwei"),
            },
          ],
        })
        .then((txHash) => {
          if (isOpen) {
            setStep("success");
          }
          console.log(txHash);
        })
        .catch((error) => console.error(error));
    }
  }, [
    account,
    provider,
    walletAddress,
    value,
    metaMaskFees,
    gasPrice,
    setStep,
    isOpen,
  ]);

  const estimateGas = useDebounce((val: string) => {
    if (provider && account && Number(val) > 0) {
      provider
        .request({
          method: "eth_gasPrice",
          params: [],
        })
        .then(
          (eth_gasPrice) =>
            typeof eth_gasPrice === "string" &&
            setGasPrice(Number(convertWeiToEth(eth_gasPrice, "gwei")))
        )
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, 500);

  useEffect(() => {
    estimateGas(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (provider && account) {
      provider
        .request({
          method: "eth_estimateGas",
          params: [
            {
              from: account,
              to: walletAddress,
            },
          ],
        })
        .then(
          (eth_estimateGas) =>
            typeof eth_estimateGas === "string" &&
            setEstimateFee(parseInt(eth_estimateGas, 16))
        )
        .catch((error) => console.error(error));
    }
  }, [provider, account, walletAddress]);

  const connect = useCallback(async () => {
    try {
      await sdk?.connect();
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  }, [sdk]);

  const disconnect = useCallback(async () => {
    try {
      await sdk?.terminate();
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  }, [sdk]);

  useEffect(() => {
    checkIfError(Number(value), chainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return (
    <>
      {account ? (
        <>
          <TransactionGroup>
            <AppText color={colors.gray_500} style={{ marginTop: "2rem" }}>
              {t("CONNECTED_ACCOUNT")}
            </AppText>
            <ReadInfo text={account} />
          </TransactionGroup>

          <TransactionGroup>
            <AppText color={colors.gray_500}>{t("AMOUNT_TO_DEPOSIT")}</AppText>

            <CurrencyInput
              value={value}
              setValue={(value) => handleChangeValue(value)}
              currency="ETH"
              placeholder="0"
              noSelect
            />
            <InfoLine>
              <WalletIcon />
              <AppText color={colors.gray_600} fontWeight={400} fontSize={12}>
                {t("BALANCE")}
                {` ${convertedToEthBalance} ETH`}
              </AppText>
            </InfoLine>
          </TransactionGroup>
          <RangeInput
            value={value}
            setValue={handleChangeValue}
            currencies={currencies}
            balance={convertedToEthBalance}
            mainCurrency="ETH"
          />

          <InfoRows>
            {paymentType?.minDeposit && Number(paymentType.minDeposit) > 0 && (
              <ModalRow
                keyText={t("MINIMUM_DEPOSIT")}
                value={toLocaleStringWithCurrency(
                  paymentType.minDeposit,
                  paymentType?.name || "USD"
                )}
                isBold
              />
            )}

            {paymentType?.maxDeposit && Number(paymentType.maxDeposit) > 0 && (
              <ModalRow
                keyText={t("MAXIMUM_DEPOSIT")}
                value={toLocaleStringWithCurrency(
                  paymentType.maxDeposit,
                  paymentType?.name || "USD"
                )}
                isBold
              />
            )}
          </InfoRows>

          <MetaMaskInfoRows currencyValue={value} fees={metaMaskFees} />

          {hasError && (
            <ModalError>
              <ModalErrorStatus>
                <AppText fontSize={12} color={colors.error_700}>
                  {t("WARNING")}
                </AppText>
              </ModalErrorStatus>
              <ModalErrorInfo>
                <AppText fontSize={12} color={colors.error_700}>
                  {hasError}
                </AppText>
                {chainId !== "0x1" && (
                  <MainButton
                    onClick={disconnect}
                    variant="contained"
                    toFillAndStrokeSvg
                  >
                    <MetaMaskIcon />
                    {t("DISCONNECT_METAMASK_ACCOUNT")}
                  </MainButton>
                )}
              </ModalErrorInfo>
            </ModalError>
          )}
          <TransactionGroup>
            <ModalButton
              onClick={sendMetamask}
              disabled={
                !!hasError || isLoading || Number(value) - metaMaskFees <= 0
              }
            >
              Deposit Metamask
            </ModalButton>
          </TransactionGroup>
        </>
      ) : (
        <TransactionGroup>
          <MainButton onClick={connect} variant="contained" toFillAndStrokeSvg>
            <MetaMaskIcon />
            {t("CONNECT_METAMASK")}
          </MainButton>
        </TransactionGroup>
      )}
    </>
  );
};

export default DepositMetaMaskTab;
