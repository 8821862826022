import { useRef, useLayoutEffect, useMemo } from "react";
import type { Timer } from "helpers/types";

function debounce(func: () => void, timeout = 300) {
  let timer: Timer;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

function useDebounce(callback: (val?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(
    // @ts-ignore
    () => debounce((...args: any) => callbackRef.current(...args), delay),
    [delay]
  );
}

export default useDebounce;
