import { FC } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface CloseModalButtonProps {
  onClose: () => void;
  label?: string;
}

const CloseModalButton: FC<CloseModalButtonProps> = ({ onClose, label }) => {
  const { t } = useTranslation();
  return (
    <CloseButton
      aria-label={label ? label : t("CLOSE_MODAL")}
      onClick={onClose}
    >
      <CloseIcon />
    </CloseButton>
  );
};

export const CloseButton = styled(Button)`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  min-width: 4.4rem;
  max-width: 4.4rem;
  min-height: 4.4rem;
  max-height: 4.4rem;
  color: ${colors.gray_600};
  background: transparent;
  border: none;
  position: absolute;
  top: 1.6rem;
  right: 0.8rem;

  &:hover,
  &:focus {
    border: none;
  }

  > svg {
    min-width: 2.4rem;
    max-width: 2.4rem;
  }

  &:focus:active {
    box-shadow: 0px 0px 0px 4px ${colors.gray_100},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`;

export default CloseModalButton;
