import { FC, useState, useCallback, useMemo, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { purchasePhazeCard, getUsersPhazeProducts } from "api/phaze";
import { orderCreditcoCard, getUsersCreditcoCardsList } from "api/creditco";
import ConfirmPurchaseStep from "./ConfirmPurchaseStep";
import SuccessPurchaseStep from "./SuccessPurchaseStep";
import { GlobalStore } from "stores";
import { virtualCardDesigns, extraVirtualCardDesigns } from "helpers/consts";
import type { PurchaseData, OrderCreditcoCardReq } from "api/apiTypes";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  PhazeProductInterface,
  ModalStepsType,
  CreditcoCardTemplate,
  CreditcoCardType,
  LimitsValueInterface,
} from "helpers/types";
import routesPaths from "routes/routesPaths";

interface CardPurchaseModalProps {
  isOpen: boolean;
  product?: PhazeProductInterface;
  cryptoProduct?: CreditcoCardTemplate | null;
  productDescription?: string;
  productHowToUse?: string;
  productRermsAndConditions?: string;
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  currencies: CurrencyInterface[] | null;
  shipingDetails?: number;
  designCode?: string;
  isCrypto?: boolean;
  chosenCardType?: CreditcoCardType | "";
  deliveryMethodId?: number;
  TransactionInfos: ReactNode;
  topUpFees?: string;
  withdrawFees?: string;
  limitsInfo?: LimitsValueInterface | null;
}

const CardPurchaseModal: FC<CardPurchaseModalProps> = ({
  isOpen,
  product,
  productDescription,
  productHowToUse,
  productRermsAndConditions,
  onClose,
  currencyValue,
  currency,
  currencies,
  isCrypto,
  cryptoProduct,
  shipingDetails,
  chosenCardType,
  deliveryMethodId,
  designCode,
  TransactionInfos,
  topUpFees,
  withdrawFees,
  limitsInfo,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ModalStepsType>("confirm");
  const [orderedCardId, setOrderedCardId] = useState<number | null>(null);

  const onSubmit = () => {
    if (!currency) {
      return;
    }

    if (isCrypto && cryptoProduct && designCode) {
      if (!shipingDetails) {
        GlobalStore.setError(
          "No users shipping address found. Please contact support"
        );
        return;
      }

      setIsLoading(true);

      const purchaseData: OrderCreditcoCardReq = {
        currencyName: currency,
        cardPlanId: cryptoProduct.id,
        designCode: designCode,
        deliveryAddressId: shipingDetails,
        cardType: chosenCardType || "VIRTUAL",
      };

      if (
        chosenCardType === "PHYSICAL" &&
        typeof deliveryMethodId === "number"
      ) {
        purchaseData.deliveryMethodId = deliveryMethodId;
      }

      orderCreditcoCard(purchaseData)
        .then(({ data }) => {
          setOrderedCardId(data.id);
          getUsersCreditcoCardsList();
          setStep("success");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
      return;
    }

    if (!isCrypto && product) {
      setIsLoading(true);

      const purchaseData: PurchaseData = {
        currencyName: currency,
        productId: product.productId,
        price: Number(
          normolizeCurrenciesDecimals(
            currencyValue,
            currency || "USD",
            currencies
          )
        ),
      };
      purchasePhazeCard(purchaseData)
        .then(() => {
          getUsersPhazeProducts();
          setStep("success");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const closeModal = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (step === "success") {
      if (isCrypto && orderedCardId) {
        navigate(`${routesPaths.usersCryptoCard}${orderedCardId}`);
      }
      if (!isCrypto && orderedCardId) {
        navigate(`${routesPaths.usersGiftCard}${orderedCardId}`);
      }
    }
    onClose();
  }, [step, isLoading, onClose, orderedCardId, isCrypto, navigate]);

  const cardImage = useMemo<string>(() => {
    if (isCrypto && cryptoProduct) {
      return cryptoProduct.designCodes[0] === "CA02"
        ? extraVirtualCardDesigns[cryptoProduct.currencyName]?.image || ""
        : virtualCardDesigns[cryptoProduct.designCodes[0]]?.image || "";
    }

    if (!isCrypto && product) {
      return product.productImage;
    }

    return "";
  }, [isCrypto, cryptoProduct, product]);

  const brandName = useMemo<string>(() => {
    if (isCrypto && cryptoProduct) {
      return cryptoProduct.cardBrand;
    }

    if (!isCrypto && product) {
      return product.brandName;
    }

    return "";
  }, [isCrypto, cryptoProduct, product]);

  const chosenCurrency = useMemo<string>(() => {
    if (isCrypto && cryptoProduct) {
      return cryptoProduct.currencyName;
    }

    if (!isCrypto && product) {
      return product.currency;
    }

    return "";
  }, [isCrypto, cryptoProduct, product]);

  return (
    <BasicModal
      onExited={() => setOrderedCardId(null)}
      isOpen={isOpen}
      onClose={closeModal}
      title="Purchase card"
    >
      {isLoading && <Preloader isStatic />}

      {step === "confirm" && (
        <ConfirmPurchaseStep
          cardImage={cardImage}
          brandName={brandName}
          chosenCurrency={chosenCurrency}
          currency={currency}
          currencyValue={currencyValue}
          onClose={closeModal}
          onSubmit={onSubmit}
          cashbackPercent={cryptoProduct?.cashbackPercent}
          isCrypto={isCrypto}
          TransactionInfos={TransactionInfos}
        />
      )}

      {step === "success" && (
        <SuccessPurchaseStep
          cardImage={cardImage}
          brandName={brandName}
          productDescription={productDescription}
          productHowToUse={productHowToUse}
          productRermsAndConditions={productRermsAndConditions}
          onClose={closeModal}
          cashbackPercent={cryptoProduct?.cashbackPercent}
          isCrypto={isCrypto}
          TransactionInfos={TransactionInfos}
          topUpFees={topUpFees}
          withdrawFees={withdrawFees}
          limitsInfo={limitsInfo}
          level={cryptoProduct?.level}
        />
      )}
    </BasicModal>
  );
};

export default observer(CardPurchaseModal);
