import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MainButton, SquereButton } from "components/Buttons";
import { AppText } from "components";
import { useMediaQuery } from "helpers/hooks";

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { colors } from "helpers/consts";

interface PaginationProps {
  totalItems: number;
  page: number;
  itemsPerPage: number;
  onPageChange: (arg: number) => void;
  withBorder?: boolean;
}

const Pagination: FC<PaginationProps> = ({
  totalItems,
  page,
  onPageChange,
  itemsPerPage,
  withBorder,
}) => {
  const { t } = useTranslation();
  const laptop = useMediaQuery("(min-width: 30em)");
  const desktop = useMediaQuery("(min-width: 48em)");
  const onNextClick = () => {
    onPageChange(page + 1);
  };
  const onBackClick = () => {
    onPageChange(page - 1);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      {totalItems > itemsPerPage && (
        <PaginationContainer $withBorder={withBorder}>
          <AppText color={colors.gray_700}>
            {laptop ? `${t("PAGE")} ` : ""}
            {page + 1} {t("OF")} {totalPages}
          </AppText>
          {/* {!!onShowAll && (
        <MainButton onClick={onShowAll} variant="text">
          {t("SHOW_ALL")}
        </MainButton>
      )} */}

          <Controls>
            {desktop ? (
              <>
                <BackButton disabled={page + 1 <= 1} onClick={onBackClick}>
                  <ArrowRightIcon />
                  {t("PREVIOUS")}
                </BackButton>
                <MainButton
                  disabled={page + 1 === totalPages}
                  onClick={onNextClick}
                >
                  {t("NEXT")} <ArrowRightIcon />
                </MainButton>
              </>
            ) : (
              <>
                <BackMobileButton
                  disabled={page + 1 <= 1}
                  onClick={onBackClick}
                >
                  <ArrowRightIcon />
                </BackMobileButton>
                <SquereButton
                  disabled={page + 1 === totalPages}
                  onClick={onNextClick}
                >
                  <ArrowRightIcon />
                </SquereButton>
              </>
            )}
          </Controls>
        </PaginationContainer>
      )}
    </>
  );
};

interface PaginationContainerInterface {
  $withBorder?: boolean;
}
const PaginationContainer = styled.div<PaginationContainerInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem 1.6rem;
  ${({ $withBorder }) =>
    $withBorder && `border-top: solid 1px ${colors.gray_100};`}

  & > div {
    margin-left: 2rem;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:first-of-type) {
    margin-left: 1.2rem;
  }
`;

const BackButton = styled(MainButton)`
  > svg {
    transform: rotate(180deg);
  }
`;

const BackMobileButton = styled(SquereButton)`
  > svg {
    transform: rotate(180deg);
  }
`;

export default Pagination;
