import { FC } from "react";
import { Outlet, Navigate } from "react-router-dom";
import routesPaths from "routes/routesPaths";

interface PublicRouteProps {
  isAuth: boolean;
}

const PublicRoute: FC<PublicRouteProps> = ({ isAuth }) => {
  if (isAuth) {
    return <Navigate to={routesPaths.dashboard} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
