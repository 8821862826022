import { FC } from "react";
import styled from "styled-components";
import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import { CustomTextFieldProps } from "./types";
import { colors } from "helpers/consts";

const TextInput: FC<CustomTextFieldProps> = ({
  value,
  label,
  disabled,
  leftIcon,
  type,
  maxWidth,
  minWidth,
  placeholder,
  ...props
}) => {
  return (
    <StyledTextField
      value={value}
      label={label ? label : null}
      disabled={disabled}
      fullWidth
      variant="outlined"
      type={type ? type : "text"}
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      placeholder={placeholder ? placeholder : undefined}
      InputProps={
        leftIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">{leftIcon}</InputAdornment>
              ),
            }
          : undefined
      }
      {...props}
    />
  );
};

type TextFieldAdditionalProps = {
  $maxWidth?: string;
  $minWidth?: string;
};

type StyledTextFieldProps = TextFieldProps & TextFieldAdditionalProps;

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}
  .MuiInputBase-input {
    padding: ${({ InputProps }) =>
      InputProps?.startAdornment ? "1rem 1.4rem 1rem 0" : "1rem 1.4rem"};
    font-size: 1.6rem;
    color: ${colors.gray_900};
    border-radius: 0.8rem;
    background-color: ${colors.white};

    &::placeholder {
      color: ${colors.gray_500};
      font-size: 1.6rem;
      opacity: 0.7;
    }
    &:disabled {
      color: ${colors.gray_500};
      background-color: ${colors.gray_50};
    }
    &:disabled + fieldset {
      border: 1px solid ${colors.gray_100};
    }
  }
  .MuiInputBase-multiline {
    padding: 0.4rem 0;
    min-height: 8rem;
    align-items: flex-start;
    ${({ disabled }) =>
      disabled &&
      `color: ${colors.gray_500};
      background-color: ${colors.gray_50};
      & > fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid ${colors.gray_100};
    } `}
  }

  .MuiInputBase-root,
  .MuiInputBase-multiline {
    border-radius: 0.8rem;
    background-color: ${({ disabled }) =>
      disabled ? colors.gray_50 : colors.white};

    &.Mui-disabled {
      color: ${colors.gray_400};
    }

    &:not(.Mui-disabled):hover,
    &:not(.Mui-disabled):focus {
      fieldset {
        border: 1px solid ${colors.primary_500};
      }
    }
    &:not(.Mui-disabled):focus-within {
      fieldset {
        border: 2px solid ${colors.primary_500};
      }
    }
  }

  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.primary_500};
  }
  & .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.error_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.error_500};
  }
  fieldset {
    border: 1px solid ${colors.gray_300};
    border-radius: 0.8rem;
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${colors.error_500};
    font-size: 1.1rem;
  }
  textarea.MuiInputBase-inputMultiline {
    line-height: 1.2;
    padding: 0.8rem 1.4rem;
  }
`;

export default TextInput;
