import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseModalButton, ModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import {
  ModalHeading,
  ModalContent,
  ModalSemiHeading,
  WarningStatusInfo,
} from "./styled";
import AppText from "components/AppText";
import type { UserLevel } from "helpers/types";
import routesPaths from "routes/routesPaths";

export interface WithdrawLimitInfo {
  level: "DAILY" | "MONTHLY" | "YEARLY";
  limit: number;
  availableAmout: number;
}

interface LevelLimitsInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  info: WithdrawLimitInfo;
  userLevel: UserLevel;
}
const LevelLimitsInfoModal: FC<LevelLimitsInfoModalProps> = ({
  onClose,
  isOpen,
  info,
  userLevel,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading $centered>{t("OUT_OF_LIMITS")}</ModalHeading>

        <ModalSemiHeading style={{ margin: "1.2rem 0", textAlign: "center" }}>
          {t(`TRANSACTION_EXCEEDS_LIMITS_${info.level}`)}
        </ModalSemiHeading>

        <WarningStatusInfo>
          <AppText style={{ marginBottom: "1.2rem" }}>
            You have available{" "}
            <b>{toLocaleStringWithCurrency(info.availableAmout, "USD")}</b> from{" "}
            <b>{toLocaleStringWithCurrency(info.limit, "USD")}</b>
          </AppText>

          {userLevel !== "SENIOR" ? (
            <>
              <AppText style={{ marginBottom: "1.2rem" }}>
                {t("INCREASE_LIMITS_TEXT")}
              </AppText>
              <ModalButton
                style={{ marginTop: "2rem" }}
                onClick={() => navigate(routesPaths.verification)}
              >
                {t("INCREASE_LIMITS")}
              </ModalButton>
            </>
          ) : (
            <ModalButton style={{ marginTop: "2rem" }} onClick={onClose}>
              {t("CLOSE")}
            </ModalButton>
          )}
        </WarningStatusInfo>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(LevelLimitsInfoModal);
