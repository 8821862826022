import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
} from "../styled";
import StakeMillionRows from "pages/EarningPage/components/StakeMillionRows";

import type { CurrencyInterface } from "helpers/types";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import stakeImg from "assets/images/stake_success.jpg";

interface SuccessPurchaseStepProps {
  onClose: () => void;
  currencyValue: string;
  currencies: CurrencyInterface[] | null;
}

const SuccessPurchaseStep: FC<SuccessPurchaseStepProps> = ({
  onClose,
  currencyValue,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={stakeImg} alt="decorative" />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_600}
              lineHeight={30}
            >
              Golden Million
            </AppText>
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {t("STAKE")} {t("CONFIRMED")}
            </AppText>
            <StatusInfoLine>
              {currencyIcons["USDT"]}
              <AppText
                fontSize={20}
                fontWeight={600}
                color={colors.gray_700}
                lineHeight={30}
              >
                {normolizeDecimalsWithNoCode(
                  Number(currencyValue),
                  "USDT",
                  currencies
                )}
              </AppText>
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                USDT
              </AppText>
            </StatusInfoLine>
          </InfoColumn>

          <StakeMillionRows
            currencies={currencies}
            currencyValue={currencyValue}
          />

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessPurchaseStep;
