import axios from "axios";
import type { FoxpayGetBankAccRes, FoxpayWithdrawReq } from "./apiTypes";
import type { AvailableTradeOptions } from "helpers/types";

export const getFoxpayBankAccDetails = async (
  currencyName: AvailableTradeOptions
) =>
  axios.get<FoxpayGetBankAccRes>(
    `/foxpay/bank-account/currency/${currencyName}`
  );

export const foxpayWithdraw = async (data: FoxpayWithdrawReq) =>
  axios.post("/foxpay/withdraw", data);
